.file-upload {
  width: 100%;
  cursor: pointer;
  background: #ffffff;
  border: 2px dashed #eef1f8;
  border-radius: 8px;

  &__modal {
    width: 40vw !important;

    @include respond(1000px) {
      width: 60vw !important;
    }

    @include respond(600px) {
      width: 80vw !important;
    }

    @include respond(500px) {
      width: 95vw !important;
    }

    &.multi {
      border-radius: 0;

      .file-upload__modal__header {
        justify-content: space-between;
        padding-top: 32px;
        h1 {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    &__accordion {
      .MuiAccordionSummary-content {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    &__files {
      display: flex;
      flex-direction: column;
      overflow: scroll;
      gap: 40px;
      max-height: 600px;
    }

    &__header {
      display: flex;
      justify-content: flex-end;
      padding: 16px;
      border-bottom: 1px solid #e2e4e8;
    }

    &__body {
      &__wrap {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 16px;
        padding-bottom: 48px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__heading h1 {
      color: var(--Topship-Colors-Black-Primary, #0f1414);
      font-family: 'General Sans';
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
    }

    &__description span {
      color: var(--Topship-Colors-Black-Secondary, #545859);
      font-family: 'General Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }

    &__footer {
      &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
      }

      &__button {
        width: 100%;
        border-radius: 8px;
        padding: 12px 24px;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'General Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        &.secondary {
          border: 1px solid var(--Topship-Colors-Input-Outline, #e2e4e8);
          background: var(--Topship-Colors-White-100, #fff);
          color: var(--Topship-Brand-Blue, #22428f);
        }
        &.primary {
          border: none;
          color: var(--Topship-Colors-White-100, #fff);
          background: var(--Topship-Brand-Blue, #22428f);
          &.button-loading {
            background: var(--Blue-200, #9bb8ff);
          }
        }
      }
    }
  }

  &__wrap {
    gap: 16px;
    display: flex;
    padding: 36px 9.03%;
    flex-direction: column;
    align-items: center;
    @include respond(500px) {
      padding: 8px;
    }
  }

  &__image {
    &.is-modal {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__heading {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #0f1414;
    span {
      color: #22428f;
      font-weight: 700;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: hsl(192, 3%, 34%);
    @media screen and (min-width: 48rem) {
      max-width: 50%;
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    > * {
      width: 70% !important;
    }

    @include respond(500px) {
      flex-direction: column;
      > * {
        width: 100% !important;
      }
    }
  }

  &__result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e5edff;
    border-radius: 8px;
    padding: 8px 16px;
    @include respond(500px) {
      width: 100%;
    }

    &.no-image {
      gap: 16px;
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 8px;
    }

    > span {
      font-size: 16px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 50%;
      font-family: 'General Sans';
    }

    button {
      border: none;
      background: white;
      display: flex;
      align-items: center;
      border-radius: 30px;
      padding: 8px 12px;
      gap: 4px;
      img {
        @include respond(500px) {
          width: 20px;
          height: 20px;
        }
      }
      span {
        color: #f05d3d;
        font-family: 'General Sans';
        font-size: 16px;
        font-weight: 500;
      }

      @include respond(500px) {
        padding: 4px 6px;
      }
    }
  }
}

.file__uploaded__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  background-color: #f8fbff;
  border: 1px solid #eef1f8;
  border-radius: 0.8rem;
  margin-top: 0.8rem;
}
.uploaded__file__name-and-size {
  display: flex;
  gap: 1.2rem;
}

.uploaded__file__data {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

.uploaded__file__name {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 0 !important;
}

.uploaded__file__size {
  color: #545859;
  margin-bottom: 0 !important;
}
