.form-container {
  width: 100%;

  form {
    width: 100%;
    padding: 10px;
  }
  .MuiInputBase-root {
    font-size: 14px;
    background: #ffffff !important;
    border: 1px solid #e2e4e8 !important;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
  }
  .MuiSelect-select {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    min-height: 23px !important;
  }

  .form-item {
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .password {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }

  .form-item-label {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
  }

  #password::placeholder {
    font-size: 1.5rem;
  }

  #password label {
    font-size: 1.5rem;
  }

  .new-here {
    padding: 1.5rem 0;
    font-size: 1.5rem;
    text-align: center;
  }

  #password-helper-text {
    color: red;
    margin: 3px 0 0;
    font-size: 1.2rem;
  }

  .remember-me {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin: 3rem 0 !important;
  }

  .remember-me span span {
    padding: 0px;
  }

  .link-to {
    color: #22428f;
  }
}
