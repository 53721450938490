$desktop_average: 1350px;

.new-get-quote {
  &__prompt {
    animation: fadeIn 1s;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1071;
    margin-top: 65px;
    display: none;
    overflow: scroll;
    outline: 0;
    height: fit-content;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width: 500px;

    @include respond(1200px) {
      margin-top: 4em;
    }

    @include respond(mini-mobile) {
      margin-top: 0;
      overflow: scroll;
      width: 90%;
      min-height: 66vh;
      max-height: 88vh;
      margin-top: 4em;
    }
    &.fade {
      opacity: 0;
      transition: opacity 0.15s linear;
    }
    &.fade.show {
      opacity: 1;
      display: block;
    }
    &__wrap {
      display: flex;
      flex-direction: column;
      height: fit-content;
      max-height: 90vh;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #0f1414;
      }
      button {
        box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
        border-radius: 4px;
        border: 0;
        padding: 9px 24px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
        background: #ffffff;
        border: 1px solid #e2e4e8;
        color: #22428f;
        &:disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }
    &__body {
      background: #f8fbff;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      min-height: fit-content;
      max-height: 500px;
      overflow: scroll;

      @include respond(mini-mobile) {
        height: 400px;
      }

      &__wrap {
        display: flex;
        flex-direction: column;
        padding: 32px 24px;
        gap: 16px;
        @include respond(mini-mobile) {
          padding: 16px;
        }
      }
      &__options {
        background: #eef1f8;
        border-radius: 12px;
        &__wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px;
          gap: 5px;
        }
        &__item {
          flex: 1;
          display: flex;
          justify-content: center;
          padding: 7px 24px;
          transition: all 0.4s ease;
          cursor: pointer;
          @include respond(mini-mobile) {
            padding: 8px 1px;
          }
          span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            text-align: center;
          }
          &.active {
            background: #ffffff;
            box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            span {
              color: #22428f;
            }
          }
        }
      }
    }
    &__weight-wrapper {
      width: 100%;
      margin-top: 20px;
      padding: 0;
      margin-left: 10px;

      .input {
        margin-bottom: 20px;
      }

      label {
        margin-bottom: 8px !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 22px !important;
        color: #0f1414;

        @include respond(mini-mobile) {
          font-size: 14px !important;
        }
      }
    }
    &__input {
      div {
        text-transform: capitalize !important;
      }
      label {
        margin-bottom: 8px !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 22px !important;
        color: #0f1414;
      }
      &:last-child {
        label {
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }
    }
    &__results {
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 16px;
      }
      &__item {
        display: flex;
        flex-direction: column;
        gap: 7px;
        background: #ffffff;
        border: 1px solid #e2e4e8;
        box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        padding: 20px;
        width: 100%;
        &__header span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #545859;
        }
        &__address {
          display: flex;
          align-items: center;
          gap: 8px;
          span {
            text-transform: capitalize;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #0f1414;
          }
        }
      }
    }
    &__options {
      &__wrap {
        display: flex;
        flex-direction: column;
        gap: 13px;
      }
      &__header span {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        color: #545859;
      }
      &__list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        &__item {
          display: flex;
          flex-direction: column;
          background: #ffffff;
          border: 1px solid #e2e4e8;
          box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          transition: all 0.3s ease;
          cursor: pointer;
          &.active {
            box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12),
              0px 0px 0px 3px #d4e5ff;
            border: 2px solid #22428f;
            .new-shop-n-ship__prompt__body__options__list__item {
              &__name h3 {
                color: #22428f;
              }
            }
          }
          &__wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;
            &:not(.discount) {
              padding: 0 16px 8px 16px;
            }
            &.no-left-padding {
              padding: 0 0 8px 0;
            }
            @include respond(mini-mobile) {
              padding: 16px;
              flex-direction: column-reverse;
              align-items: flex-start;
            }
          }
          &__name h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            text-transform: uppercase;
            color: #0f1414;
          }
          &__address span {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #545859;
          }
          &__price {
            display: flex;
            flex-direction: column;
            gap: 1em;

            &:not(.discount) {
              align-self: flex-end;
            }
            @include respond(1200px) {
              flex-direction: column;
            }

            span {
              padding: 3px 10px;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 19px;
              border-radius: 16px;
              &:not(.no-discount) {
                background: rgba(16, 197, 78, 0.1);
                color: #10c54e;
                @include respond(mini-mobile) {
                  margin-top: 5px;
                }
              }
              &.no-discount {
                background: #d3d3d366;
                color: rgba(128, 128, 128, 0.844);
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }
    &__footer {
      &__wrap {
        padding: 20px 24px;
        @include respond(mini-mobile) {
          padding: 16px;
        }
      }
      &__actions {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 8px;
        button {
          box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
          border-radius: 4px;
          border: 0;
          padding: 9px 24px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 140%;
          &:first-child {
            background: #ffffff;
            border: 1px solid #e2e4e8;
            color: #22428f;
          }
          &:last-child {
            background-color: #22428f;
            color: #ffffff;
            &:disabled {
              opacity: 0.4;
              background: #b7b7b7;
            }
          }
        }
      }
    }
    &__backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #000;
      display: none;
      &.fade {
        opacity: 0;
        transition: opacity 0.15s linear;
      }
      &.fade.show {
        display: block;
        opacity: 0.5;
      }
    }
  }
}
