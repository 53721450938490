$desktop_average: 1350px;
$desktop_minimum: 1024px;
$medium: 1000px;
$medium_index: 999px;
.new-signup {
  height: 100vh;
  animation: fadeIn 0.5s;
  &__wrap {
    padding: 0 0 0 18.5%;
    // padding: 0 0 155px 266px;
    display: flex;
    gap: 15%;
    // gap: 267px;
    width: 100vw;
    @media screen and (min-width: $medium) and (max-width: $desktop_average) {
      padding-left: 4.5%;
      gap: 7%;
    }
    @include respond($medium_index) {
      padding: 0 10%;
      justify-content: center;
    }
    @include respond(mini-mobile) {
      padding: 0 16px;
    }
  }
  &__main {
    width: 55%;
    @include respond($medium_index) {
      width: 100%;
    }
    &__wrap {
      padding: 115px 0 0 0;
      display: flex;
      flex-direction: column;
      gap: 32px;
      //   gap: 32px;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  &__heading * {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: #0f1414;
  }
  &__footer {
    display: flex;
    flex-direction: column;
  }
  &__acknowledgment {
    margin-bottom: 32px;
    *:not(a) {
      margin: 0;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #545859;
    }
    a {
      text-decoration: underline;
    }
  }
  &__button {
    width: 100%;
    margin-bottom: 14px;
    button {
      &:hover {
        background: var(--color-blue-dark2);
      }
      width: 100%;
      background: #22428f;
      border-radius: 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff;
      padding: 14px 50px;
      border: 0;
      outline: 0;
      @include respond(400px) {
        padding: 14px 40px;
      }
    }
  }
  &__link * {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #545859;
    a {
      text-decoration: none;
      color: #22428f;
      font-weight: 500;
    }
  }
  &__intro {
    background: #f2f7fd;
    height: 100vh;
    width: 45%;
    @include respond($medium_index) {
      display: none;
    }
    &__wrap {
      padding: 246.5px 152px 0 76px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      @media screen and (min-width: $medium) and (max-width: $desktop_average) {
        padding: 246.5px 90px 0 40px;
      }
    }
    &__item {
      width: 368px;
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__icon {
        margin-bottom: 64px;
        width: 50px;
        height: 50px;
      }
      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 16px;
        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #22428f;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #545859;
        }
      }
    }
    &__dots {
      margin-top: 64px;
      display: flex;
      gap: 4px;
      width: 100%;
      align-items: center;
    }
    &__dot {
      outline: 0;
      border: 0;
      border-bottom: 4px solid #ccdef7;
      border-radius: 4px;
      width: 100%;
      &.active {
        border-bottom: 4px solid #1b72e7;
      }
    }
  }
  #input-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 16px 0px 0px 10px;

    label {
      font-style: normal;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      color: #0f1414;
      margin: 0px;
      text-transform: none !important;
      font-family: inherit;
      width: fit-content;

      span {
        font-style: normal;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        font-family: inherit;
        padding-left: 0px;
      }
    }

    .radio-group {
      display: flex;
      flex-direction: column;
    }

    .radio-group-option {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 15px 0px 0px 0px !important;
      cursor: pointer;

      span {
        margin-left: 10px;
        font-size: 14px;
        line-height: 20px;
      }
    }

    input[type='radio'] {
      cursor: pointer;
    }

    input[type='file'] {
      margin-top: 10px;
    }

    small {
      display: block;
      color: #d32f2f;
      margin: 5px;
    }

    #upload-button {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      .filename {
        margin-top: 10px;
        font-size: 1.5rem;
      }
      label {
        padding: 9px 7px;
        margin-right: 1rem;
        border-radius: 20px;
        border: 2px solid #22428f;
        color: #22428f;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        width: fit-content;
        text-transform: capitalize;
      }
    }
  }
}
