.share-buttons {
  padding-bottom: 23px;
  width: 100%;
  .share-groups {
    display: flex;
    justify-content: space-between;
    > span {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      span {
        font-family: 'General Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 12px;
        color: #000000;
      }
    }

    &:nth-child(1) {
      margin-bottom: 30px;
    }
  }
}
