$desktop_average: 1350px;

.order-summary {
  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__alert {
    border-radius: 8px;
    background: rgba(146, 222, 255, 0.58);
    padding: 10px;
    align-self: center;
    p {
      color: #22428f !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 24px !important;
      span {
        font-weight: bold !important;
      }
    }
  }
  &__header {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1d1d1d;
    margin-bottom: 6px;
    @include respond(mini-mobile) {
      margin-top: 20px;
    }
  }
  &__shipment {
    background: #ffffff;
    border: 1px solid #e2e4e8;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    &.mobile-wrap {
      @include respond(mini-mobile) {
        margin: 0 -24px !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
    &__wrap {
      padding: 24px;
      @include respond(mini-mobile) {
        padding: 16px;
      }
    }
    &__addresses {
      display: flex;
      flex-direction: column;
      padding: 24px 24px 24px 9px;
      border-bottom: 1px solid #eeeeee;
      @include respond(mini-mobile) {
        padding: 16px 16px 16px 9px;
      }
    }
    &__address {
      position: relative;
      padding-left: 15px;
      border-left: 1px dashed #b7b7b7;
      // padding-bottom: 86px;
      &.multiple {
        padding-bottom: 60px;
      }
      &.final {
        padding-bottom: 0;
      }
      &.deliver-to::before {
        content: '';
        position: absolute;
        display: block;
        width: 14px;
        height: 14px;
        border: 1px solid #ffffff;
        transform: translate(-155%, -3px);
        background: #14cc3c;
        border-radius: 0px;
      }
      // &.pickup {
      //   background-color: #14cc3c;
      //   @include respond($desktop_average) {
      //     padding-bottom: 70px;
      //   }
      //   &::before {
      //     background: #22428f;
      //     border-radius: 50%;
      //   }
      // }
      &::before {
        content: '';
        background: #22428f;
        position: absolute;
        display: block;
        width: 14px;
        height: 14px;
        border: 1px solid #ffffff;
        transform: translate(-155%, -3px);
        border-radius: 50%;
      }
      // &:last-child {
      //   border-left: none;
      // }
      &__wrap {
        display: flex;
        justify-content: space-between;
        @include respond($desktop_average) {
          flex-direction: column;
          gap: 16px;
          align-items: flex-start;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      &__label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #545859;
      }
      &__name {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #545859;
        margin-bottom: 4px;
      }
      &__location {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #0f1414;
        margin-bottom: 4px;
        &.multiple-delivery {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        @include respond(mini-mobile) {
          font-size: 13px;
        }
      }
      &__meta {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #545859;
        @include respond(mini-mobile) {
          font-size: 14px;
        }
      }
      &__items {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #545859;
      }
      &__options {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #0f1414;
        margin-bottom: 4px;
      }
      &__action {
        // width: 15rem;
        // background-color: #14cc3c;
        button {
          cursor: pointer;
          background: #eef1f8;
          border-radius: 46px;
          padding: 8px 10px !important;
          gap: 6px;
          &:disabled {
            opacity: 0.4;
          }
          span {
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 19px !important;
            color: #22428f !important;
            text-transform: none !important;
          }
          svg path {
            fill: #22428f;
          }
        }
      }
    }
    &__meta {
      position: relative;
      // z-index: 2;
      padding: 16px 0;
      h4 {
        font-size: 18px !important;
      }
      &__wrap {
        display: flex;
        justify-content: space-between;
        padding: 2rem 0rem;
        flex-direction: column;
        gap: 2rem;

        &.multiple-delivery {
          @media screen and (min-width: 48.2rem) {
            width: 100%;
            overflow: auto;
            gap: 10px;
            align-items: flex-start;
          }
        }
        @media screen and (min-width: 48.2rem) {
          flex-direction: row;
          align-items: flex-start;
          gap: 16px;
        }
      }
      &__box {
        display: flex;
        justify-content: space-between;
      }
      &__content {
        width: 15rem;
        display: flex;
        flex-direction: column;
        gap: 4px;

        &__label {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #545859;
          margin: 0rem !important;
        }
        &__value {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #0f1414;
          margin: 0rem !important;
          @include respond(mini-mobile) {
            font-size: 14px;
          }
        }
      }
      &__action {
        width: 15rem;
        button {
          cursor: pointer;
          background: #eef1f8;
          border-radius: 46px;
          padding: 8px 10px !important;
          gap: 6px;
          span {
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 19px !important;
            color: #22428f !important;
            text-transform: none !important;
          }
          svg path {
            fill: #22428f;
          }
        }
      }
    }

    &__meta:not(:last-child) {
      border-bottom: 1px solid #eef1f8;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__item {
    background: #ffffff;
    border: 1px solid #e2e4e8;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    &__wrap {
      display: flex;
      flex-direction: column;
      padding: 24px;
    }
    &__actions {
      align-self: flex-start;
      display: flex;
      align-items: center;
      gap: 16px;

      button {
        cursor: pointer;
        padding: 8px 10px !important;
        gap: 6px;
        span {
          font-style: normal !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 19px !important;
          text-transform: none !important;
        }
      }
      button:first-child {
        border-radius: 36px;
        background: rgba(240, 93, 61, 0.05);
        span {
          color: #f05d3d !important;
        }
        svg path {
          fill: #f05d3d;
        }
      }
      button:last-child {
        border-radius: 46px;
        background: #eef1f8;
        span {
          color: #22428f !important;
        }
        svg path {
          fill: #22428f;
        }
      }
    }
    &__rows {
      display: flex;
      flex-direction: column;
    }
    &__row {
      &:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
      }
      &__wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 16px 0;
        @include respond(tablet) {
          flex-direction: column;
          gap: 16px;
        }
        &:not(:nth-child(2)) {
          padding: 16px 22% 16px 0;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        gap: 4px;
        &__label {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #545859;
        }
        &__value {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #0f1414;
          @include respond(mini-mobile) {
            font-size: 14px;
          }
        }
      }
    }
  }
  &__coupon {
    background: #ffffff;
    border: 1px solid #e2e4e8;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    &__wrap {
      padding: 24px;
      @include respond(mini-mobile) {
        padding: 16px;
      }
    }
    .MuiInputBase-root.MuiInput-root {
      border: 0 !important;
      box-shadow: none !important;
    }
  }
  &__additional-notes {
    background: #ffffff;
    border: 1px solid #e2e4e8;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    &__wrap {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      padding: 24px;
      @include respond($desktop_average) {
        flex-direction: column;
      }
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #0f1414;
    }
    span:not(.button-span) {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #545859;
      margin-bottom: 4px;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 64px 0;
    @include respond(mini-mobile) {
      flex-direction: column-reverse;
    }
    &__action {
      cursor: pointer;
      width: 100%;
      background: #ffffff;
      border: 1px solid #e2e4e8;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 14px 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #22428f;
      text-align: center;
    }
  }

  .delivery-address-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .delivery-address-copy {
    color: #22428f;
    font-weight: 600;
    font-size: 15px;
  }

  .items-container {
    width: 100%;
    gap: 2rem;
    padding: 1.6rem 0rem;
    border-top: 1px solid #eef1f8;
    border-bottom: 1px solid #eef1f8;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  @media screen and (min-width: 48.2rem) {
    .items-container {
      flex-direction: row;
    }
  }

  .item-detail {
    min-width: 15rem;
    &__label {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #545859;
      text-align: left;
    }

    &__value {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #0f1414;
      text-align: left;
      margin-bottom: 0rem;
      @include respond(mini-mobile) {
        font-size: 14px;
      }
    }
  }

  // .agreement-check {
  //   width: 6rem;
  //   height: 2.4rem;
  // }

  // @media screen and (min-width: 26rem) {
  //   .agreement-check {
  //     width: 5.2rem;
  //     height: 2.4rem;
  //   }
  // }

  .desktop-sum__view {
    display: none;
  }

  .mobile-sum__view {
    display: block !important;
  }

  @media screen and (min-width: 48rem) {
    .mobile-sum__view {
      display: none !important;
    }
    .desktop-sum__view {
      display: flex;
    }
  }
}

.payment__successful__content {
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
  padding: 6.8rem 3.2rem;
  background-color: #ffffff;
}

.payment__successful__main__text {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0%;
  text-align: center;
  margin-bottom: 0 !important;
  color: #0f1414;
}

.payment__successful__sub__text {
  color: #545859;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 18.9px;
  letter-spacing: 0%;
  text-align: center;
  margin-bottom: 0 !important;
  max-width: 35rem;
}

.payment__successful__cta {
  background-color: #22428f;
  font-family: General Sans;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 16.8px;
  letter-spacing: 0%;
  color: #ffffff;
  padding: 0.9rem 4.8rem;
  border-radius: 0.4rem;
  border: none;
}
