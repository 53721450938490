.tracking__id__modal {
  background: #0f141499;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  animation: fadeIn 0.5s;
}

.update__content__containers {
  height: 100%;
  width: fit-content;
  overflow: scroll;
}

.tracking__id__modal-content {
  background-color: #fff;
  width: 100%;
  height: 100%;
  // overflow: scroll;
}

@media screen and (max-width: 62.5rem) {
  .expand__to__full__width {
    width: 100% !important;
  }
}

.heading__text-container {
  display: flex;
  justify-content: space-between;
  padding: 2.4rem;
  border: 1px solid #e2e4e8;
}

.heading__texts {
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  padding-top: 0rem;
  gap: 0.8rem;
}

.heading__text {
  font-size: 2rem;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  color: #0f1414;
  margin-bottom: 0rem !important;
}

.heading__sub__text-main {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 24.3px;
  text-align: left;
  color: #0f1414;
  margin-bottom: 0rem !important;
}

.heading__sub__text-sub {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
  color: #545859;
  margin-bottom: 0rem !important;
}

.progress__bar__container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 2.4rem;
}

.process__num__text {
  display: flex;
  align-items: center;
}

.document__labels {
  display: flex;
  flex-direction: column;
}
.document__labels-two {
  display: flex;
  gap: 0.4rem;
  margin: 1.6rem 0rem;
}

.file__name__label {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 0%;
  margin-bottom: 0 !important;
}

.file__name {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 0%;
  color: #737c7f;
  margin-bottom: 0 !important;
}

.progress__text {
  color: #545859;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 0%;
  margin-bottom: 0 !important;
}

.progess__bar__box {
  width: 100%;
  background-color: #eef1f8;
  height: 0.8rem;
  border-radius: 3rem;
}

.progress__bar {
  display: flex;
  gap: 1.6rem;
  // padding: 2.4rem;
  border: 1px solid #e2e4e8;
  width: 0%;
  border-radius: 3rem;
  background-color: #22428f;
  height: 100%;
  transition: 3s ease-in-out forwards;
}
.progress__bar__half {
  display: flex;
  gap: 1.6rem;
  // padding: 2.4rem;
  border: 1px solid #e2e4e8;
  width: 50%;
  border-radius: 3rem;
  background-color: #22428f;
  height: 100%;
  transition: 3s ease-in-out forwards;
}

.progress__bar__increase {
  animation: expandWidth 2s ease-in-out forwards;
}
.progress__bar__increase__full {
  animation: expandFullWidth 2s ease-in-out forwards;
}

.tracking__modal__items {
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.tracking__modal__item {
  padding: 2.4rem 1.6rem;
  border: 1px solid #e2e4e8;
  border-radius: 1.6rem;
}

.modal__item__heading {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 22.4px;
  text-align: left;
  margin-bottom: 0.8rem !important;
}

.modal__item__boxes {
  display: flex;
  flex-direction: column;
  border: 1px dashed #e2e4e8;
  border-radius: 0.8rem;
  padding: 0 1.6rem;
  margin-bottom: 1.6rem;
}

.modal__item__box {
  padding: 1.6rem;
  display: flex;
  gap: 3rem;
}

.modal__item__box:not(:last-child) {
  border-bottom: 1px solid #eef1f8;
}

.item__texts__box {
  width: 15rem;
}

.item__box__label__text {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 16.2px;
  text-align: left;
}

.item__box__value__text {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  margin-bottom: 0 !important;
}

.modal__item__form {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.item__form__label {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
}

.modal__ctas {
  display: flex;
  gap: 1.6rem;
  padding: 2.4rem 0;
  border-top: 1px solid #e2e4e8;
  flex-direction: column;

  @media screen and (min-width: 48rem) {
    flex-direction: row;
  }
}

.modal__cta {
  padding: 1.4rem 3rem;
  width: 100%;
  font-family: General Sans;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 19.6px;
  border-radius: 0.8rem;
  border: none;
}

.modal__cancel__cta {
  border: 1px solid #e2e4e8;
  color: #22428f;
  background-color: #ffffff;
}

.modal__complete__cta {
  color: #ffffff;
  background-color: #22428f;
}

.tracking__number__modal__error {
  font-size: 1.1rem;
  margin-top: 1.2rem;
  color: red;
}

@keyframes expandWidth {
  from {
    width: 0%;
  }

  to {
    width: 50%;
  }
}
@keyframes expandFullWidth {
  from {
    width: 50%;
  }

  to {
    width: 100%;
  }
}

@media screen and(min-width: 26rem) {
  .tracking__id__modal {
    padding: 4rem 2rem;
  }

  .modal__item__boxes {
    padding: 1rem;
  }
  .custom__submit__cta {
    font-size: 1.6rem;
  }

  .tracking__modal__items {
    padding: 2rem;
  }
}
@media screen and(min-width: 48rem) {
  .tracking__id__modal-content {
    background-color: #fff;
    width: 65.3rem;
  }

  .item__texts__box {
    width: 21rem;
  }
}

////////////////////// Shop-n-ship-update-layout

@media screen and (min-width: 62.5rem) {
  .update__steps__body__wrap {
    gap: 1rem !important;
  }

  .update__steps__label {
    padding: 6rem 2rem 0 0 !important;
  }
}
@media screen and (min-width: 70rem) {
  .update__steps__body__wrap {
    gap: 8rem !important;
  }

  .update__steps__label {
    padding: 6rem 6rem 0 0 !important;
  }
}
