.content-layout {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  margin-top: 10rem;

  width: 480px;
  min-width: 480px;
  height: calc(100vh - 100px);

  @include respond(mobile) {
    width: 100%;
    min-width: unset;
  }
}

.content-title {
  margin: 2rem 0 3rem 0;

  h1 {
    color: #0f1414;
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 38px;
  }
}
