.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.slider-arrow {
  position: absolute;
  height: 100%;
  width: 100%;
}
.arrow-btn {
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.arrow-btn svg {
  transform: scale(1.4);
  fill: #22428f;
}
.next {
  float: right;
}

@media screen and (max-width: 1311px) {
  .remove-margin {
    margin-bottom: -20px;
  }
  .add-margin {
    margin-top: 15px;
  }
}

@media screen and (max-width: 56.25em) {
  .arrow-btn svg {
    transform: scale(1);
  }
}

@media screen and (max-width: 37.5em) {
  .arrow-btn svg {
    transform: scale(0.7);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-acap47-MuiAlert-message {
  width: 100%;
}

.woot-widget-bubble {
  bottom: 0px !important;
}

.custom-disabled-input:disabled {
  color: black !important;
}

.adjusted-width {
  width: 30%;
}

@media screen and (max-width: 900px) {
  .adjusted-width {
    width: 100%;
  }
}
