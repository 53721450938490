.resources {
  font-family: 'General Sans', sans-serif;
  &__wrapper {
    padding: 56px 30px;
    @include respond(950px) {
      padding: 50px 30px;
    }
    @include respond(769px) {
      padding: 100px 16px;
    }
    p {
      font-size: 20px;
      @include respond(500px) {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
  &__content {
    margin-top: 40px;
    .all-resources-div {
      width: 100%;
      @include respond(920px) {
        width: 100%;
      }
      .resource-card {
        border: 1px solid #d8d8d8;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding: 15px;
        @include respond(600px) {
          display: block;
        }
        p {
          font-size: 20px;
          color: #000000;
          width: 60%;
          @include respond(1138px) {
            font-size: 16px;
            line-height: 26px;
          }
          @include respond(600px) {
            width: 100%;
          }
        }
        .view {
          background-color: #22428f;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          border-radius: 8px;
          padding: 16px 50px;
          text-decoration: none;
          cursor: pointer;
          a {
            color: #fff;
            white-space: nowrap;
            text-decoration: none;
          }
          @include respond(920px) {
            padding: 16px 30px;
          }
          @include respond(600px) {
            text-align: center;
            width: 100%;
            font-size: 12px;
            margin-top: 20px;
            margin-bottom: 20px;
            display: block;
          }
        }
        .download {
          background-color: #fff;
          border: 1px solid #d8d8d8;
          color: #000000;
          font-size: 14px;
          font-weight: 600;
          border-radius: 8px;
          padding: 16px 20px;
          cursor: pointer;
          @include respond(600px) {
            text-align: center;
            font-size: 12px;
            width: 100%;
          }
          a {
            color: #000000;
            white-space: nowrap;
            text-decoration: none;
          }
        }
      }
    }
  }

  .guides-tip-container {
    // background-color: red;
    border: 1px solid #e2e4e8;
    border-radius: 8px;
    display: flex;
    // position: absolute;
    align-items: center;
    padding: 10px 15px;
    // top: 35px;
    @include respond(1192px) {
      left: 50%;
    }

    @include respond(600px) {
      left: 50px;
      top: 80px;
    }
    @include respond(500px) {
      left: 20px;
    }
  }
}
