.service__closure-content {
  background-color: #ffffff;
  padding-bottom: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.8rem;
}

.service__closure-icon-and-text__content {
  background-color: #f8fbff;
  padding: 5.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  margin-bottom: 3.2rem;
  border-radius: 0.8rem 0.8rem 0 0;
}

.service__closuree-main--text {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.4rem;
  text-align: center;
  margin-bottom: 0;
}
.service__closuree-sub--text {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.96rem;
  text-align: center;
  margin-bottom: 0;
  max-width: 34.5rem;
}

.service__closure-modal-CTA {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  background-color: #22428f;
  padding: 0.9rem 1.2rem;
  color: #ffffff;
  border-radius: 0.4rem;
  box-shadow: 0px 2px 5px 0px #1018280d;
  border: none !important;
}
