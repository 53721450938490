.round-up {
  &__prompt {
    animation: fadeIn 1s;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1071;
    display: none;
    overflow: hidden;
    outline: 0;
    height: fit-content;
    width: 400px;

    @include respond(mobile) {
      overflow: scroll;
    }
    @include respond(mini-mobile) {
      width: 100%;
      height: 100vh;
    }
    &.fade {
      opacity: 0;
      transition: opacity 0.15s linear;
    }
    &.fade.show {
      opacity: 1;
      display: block;
    }
    &__wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;

      @include respond(mini-mobile) {
        display: block;
      }
    }

    &__body {
      background-color: #fff6e7;
      border-radius: 37px;
      overflow: hidden;
      width: 100%;
      height: 780px;
      padding: 40px 30px 0;

      @include respond(smaller) {
        height: 770px;
      }
      @include respond(mini-mobile) {
        height: fit-content;
        overflow: scroll;
        border-radius: 0;
      }

      &__wrap {
        background-color: #fff6e7;
        padding-top: 20px;
        width: 100%;
      }
    }

    &__backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #000;
      display: flex;
      height: 100vh;
      justify-content: center;
      align-items: center;

      &.fade {
        opacity: 0;
        transition: opacity 0.15s linear;
      }
      &.fade.show {
        display: block;
        opacity: 0.9;
      }
    }
  }
  &__content {
    padding: 0;
    width: 100%;
    position: relative;
    white-space: nowrap;

    .logo-container {
      width: 60%;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }
    .shipment-container {
      margin-top: 15px;
      cursor: pointer;
      background-color: #fff;
      border-radius: 20px;
      padding: 20px 30px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @include respond(mobile) {
        padding: 20px 26px;
      }

      h5 {
        font-size: 16px;
        line-height: 18.38px;
        color: #22428f;
        font-weight: 600;
        letter-spacing: -2.5%;

        @include respond(mobile) {
          font-size: 16px;
          line-height: 18.68px;
        }
      }

      img {
        width: 36%;
        object-fit: contain;
      }
    }
    .export-container {
      margin-top: 15px;
      cursor: pointer;
      border-radius: 20px;

      background: rgb(236, 182, 31);
      background: linear-gradient(
        90deg,
        #edb920 0%,
        #edb520 48%,
        #eeaa21 91%,
        #efa722 100%
      );
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.23) 0px 3px 6px;
      padding: 20px 40px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond(mobile) {
        padding: 20px 26px 0 0;
        margin-top: 10px;
      }

      h5 {
        font-size: 16px;
        line-height: 18.38px;
        color: #fff;
        font-weight: 600;
        letter-spacing: -2.5%;
        margin-top: -10px;

        @include respond(mobile) {
          font-size: 16px;
          line-height: 18.68px;
        }
      }

      img {
        height: 98px;
      }
    }
    .shopnship-container {
      margin-top: 15px;
      cursor: pointer;
      background-color: #fff;
      border-radius: 20px;

      padding: 20px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respond(mobile) {
        padding: 20px 26px;
        margin-top: 10px;
      }

      h5 {
        font-size: 16px;
        line-height: 18.38px;
        color: #22428f;
        font-weight: 600;
        letter-spacing: -2.5%;

        @include respond(mobile) {
          font-size: 16px;
          line-height: 18.68px;
          margin-top: 10px;
        }
      }

      img {
        width: 44%;
        object-fit: contain;
        margin-left: -10px;

        @include respond(mobile) {
          width: 38%;
        }
      }
    }
    .rating-container {
      margin-top: 15px;
      cursor: pointer;
      margin-bottom: 20px;
      background-color: #79d1f6;
      border-radius: 20px;

      padding: 10px 30px 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond(mobile) {
        margin-top: 10px;
      }

      h5 {
        font-size: 14px;
        line-height: 15.6px;
        color: #fff;
        font-weight: 600;
        letter-spacing: -2.5%;
        margin-left: 10px;
        text-align: right;

        @include respond(mobile) {
          font-size: 16px;
          line-height: 18.68px;
          margin-left: 20px;
        }
      }

      img {
        width: 32%;
        object-fit: contain;
      }
    }

    .bottom-jet {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-right: -30px;
    }
  }
}

.total-shipped {
  &__body {
    background-color: #fff6e7;
    position: relative;
    padding-top: 40px;
    border-radius: 37px;
    height: 800px;
    overflow: hidden;

    @include respond(smaller) {
      height: 790px;
    }

    @include respond(mini-mobile) {
      height: 100vh;
      border-radius: 0;
      overflow: scroll;
    }

    &__wrap {
      background-color: #fff6e7;
      padding-top: 30px;
      width: 100%;
    }
  }

  &__shipped-total {
    padding: 0;
    width: 100%;
    position: relative;

    .shipped-content {
      position: relative;
      display: flex;
      justify-content: flex-start;
      padding: 0 15%;
      margin-bottom: 20px;

      .absolute-jet1 {
        position: absolute;
        right: 30%;
        top: 28%;
        width: 55px;

        @include respond(mini-mobile) {
          right: 48%;
          top: 35%;
          width: 40px;
        }
      }
      h5 {
        margin-top: 20px;
        font-weight: 800;
        color: #22428f;
      }

      .absolute-jet2 {
        margin: 20px 0;
        display: flex;
        justify-content: flex-end;
        padding-right: 30px;
      }
    }
  }
}

.shopnship-total {
  &__body {
    background-color: #fff6e7;
    position: relative;
    padding-top: 50px;
    border-radius: 37px;
    overflow: hidden;
    height: 800px;

    @include respond(smaller) {
      height: 790px;
    }
    @include respond(mini-mobile) {
      height: 100vh;
      border-radius: 0;
      overflow: scroll;
    }

    &__wrap {
      background-color: #fff6e7;
      padding-top: 30px;
      width: 100%;
    }
  }

  &__shipped-total {
    padding: 0;
    width: 100%;
    position: relative;

    .roundup-total-img-feature {
      height: 48vh;
      img {
        width: inherit;
        height: inherit;
        object-fit: contain;
      }

      @include respond(smaller) {
        height: 53vh;
        img {
          object-fit: contain;
          width: 100%;
        }
      }
      @include respond(mini-mobile) {
        height: 70vh;
      }
    }

    .shipped-content {
      position: relative;
      display: flex;
      justify-content: flex-start;
      padding: 0 15%;
      margin-bottom: 10px;

      h5 {
        margin-top: 20px;
        font-weight: 800;
        color: #0b5ebd;
      }

      .absolute-jet2 {
        display: flex;
        justify-content: flex-end;
        padding-right: 30px;
      }
    }
  }
}

.export-total {
  &__body {
    background-color: #f4bb18;
    position: relative;
    padding-top: 50px;
    border-radius: 37px;
    overflow: hidden;
    height: 800px;

    @include respond(smaller) {
      height: 790px;
    }
    @include respond(mini-mobile) {
      height: 100vh;
      border-radius: 0;
      overflow: scroll;
    }

    &__wrap {
      background-color: #f4bb18;
      padding-top: 30px;
      width: 100%;
    }
  }

  &__shipped-total {
    padding: 0;
    width: 100%;
    position: relative;

    .shipped-content {
      position: relative;
      display: flex;
      justify-content: flex-start;
      padding: 0 15%;

      h5 {
        margin-top: 10px;
        font-weight: 800;
        color: #fff;
      }

      .absolute-jet-small {
        position: absolute;
        top: 17%;
        right: 38%;

        @include respond(mini-mobile) {
          top: 32%;
          right: 25%;
        }
      }

      .absolute-jet2 {
        display: flex;
        justify-content: flex-end;
        padding-right: 30px;
      }
    }
  }
}

.rate-total {
  &__body {
    background-color: #79d1f6;
    position: relative;
    padding-top: 50px;
    border-radius: 37px;
    overflow: hidden;
    height: 800px;

    @include respond(smaller) {
      height: 790px;
    }

    @include respond(mini-mobile) {
      height: 100vh;
      border-radius: 0;
      overflow: scroll;
    }

    &__wrap {
      background-color: #79d1f6;
      padding-top: 30px;
      width: 100%;
    }
  }

  &__shipped-total {
    padding: 0;
    width: 100%;
    position: relative;

    .roundup-total-img-rate {
      width: 100%;
      height: 50vh;
      img {
        width: inherit;
        height: inherit;
        object-fit: contain;
      }
      @include respond(smaller) {
        height: auto !important;
        object-fit: cover;
      }
    }

    .shipped-content {
      position: relative;
      display: flex;
      justify-content: flex-start;
      padding: 0 15%;

      .white-jet {
        @include respond(mini-mobile) {
          width: 30px;
        }
      }

      h5 {
        margin-top: 10px;
        font-weight: 800;
        color: #0b5ebd;
      }

      .absolute-jet2 {
        margin: 20px 0;
        display: flex;
        justify-content: flex-end;
        padding-right: 30px;
      }
    }
  }
}

.whiteBg {
  background-color: #fff;
  padding: 40px;

  @include respond(mobile) {
    min-height: 100vh;
  }
}

.small {
  margin: 0 20px 40px;
  padding-top: 30px !important;

  height: 60vh;
  overflow: hidden !important;

  @include respond(mobile) {
    border-radius: 37px !important;
    height: 55vh;
  }

  .absolute-jet1 {
    @include respond(mini-mobile) {
      right: 48%;
      top: 46%;
      width: 35px;
    }
  }

  .absolute-jet2 {
    img {
      width: 40px;
    }
  }

  .roundup-logo-small {
    width: 100px;
    object-fit: contain;
  }
  .roundup-total-img-feature {
    height: 38vh;
    width: 100%;
    img {
      width: inherit;
      height: inherit;
      object-fit: contain;
    }

    @include respond(smaller) {
      height: 53vh;
      object-fit: cover;
    }
    @include respond(mini-mobile) {
      height: 42vh;
    }
  }

  .padded-flex {
    padding: 0 30px;
  }
}

.tiny {
  top: 30%;
}

.absolute-jet1.tiny {
  width: 45px !important;
  @include respond(mini-mobile) {
    width: 30px !important;
  }
}

.roundup-logo-small {
  width: 150px;
  object-fit: contain;
}

.content-text-large {
  font-size: 40px;
  line-height: 40.99px;

  @include respond(mini-mobile) {
    font-size: 36px;
    line-height: 38px;
  }
}

.content-text-large-rate {
  font-size: 32px;
  line-height: 33.99px;
  white-space: nowrap;

  @include respond(mini-mobile) {
    font-size: 24px;
    line-height: 28px;
  }
}

.content-text-small {
  font-size: 3rem;
  line-height: 1.1;

  @include respond(smaller) {
    font-size: 2.55rem;
    line-height: 1;
  }

  @include respond(mini-mobile) {
    font-size: 2.4rem;
  }
}

.content-text-small-rate {
  font-size: 2rem;
  line-height: 1;

  @include respond(mini-mobile) {
    font-size: 1.8rem;
    line-height: 1;
  }
}
.roundup-total-img {
  height: 43%;
  width: 100%;
  img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
}

.padded-flex {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
}

////////// 2024 RoundUp Styles

.Year__roundup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background-color: #000000a1;
  z-index: 3;
  animation: fadeIn 1s;
  transition: all 0.4s 0.2s;
  overflow-y: scroll;
  backdrop-filter: blur(0.25rem);
  -webkit-backdrop-filter: blur(0.25rem);
}

.Year__roundup-container > p {
  font-family: 'Montserrat';
}

.year__roundup-content--container {
  background-color: #fff;
  // min-height: 100%;
  max-height: fit-content;
}

.heading__and__close-icon {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-bottom: 1px solid #e2e4e8;
}

.year__roundup-heading {
  font-family: 'General Sans';
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.7rem;
  text-align: left;
  margin-bottom: 0rem !important;
}

.year__roundup-content--box {
  padding: 2rem;
  background-color: #ffffff;
}

.year__roundup-ctas {
  padding: 1.5rem;
  // padding-bottom: 4rem;
  display: flex;
  gap: 1.6rem;
  border-top: 1px solid #e2e4e8;
  background-color: #ffffff;
  position: relative;
}

.round__up-save--img {
  width: 100%;
  background-color: #ffffff;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #22428f;
  border: 1px solid #e2e4e8;
  border-radius: 1rem;
}
.round__up-share--img {
  width: 100%;
  background: #22428f;
  color: #ffffff;
  border: none;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem;
  border-radius: 1rem;
}

.year__roundup-content {
  background-color: #fff7e8;
  position: relative;
  max-width: 64rem;
  height: 100%;
  padding: 3.5rem;
}

.round__up-text {
  width: 23.4rem;
  margin-bottom: 1.2rem;
}

.round__up-plane--one {
  position: absolute;
  top: 0;
  right: 0;
  width: 12.8rem;
}

.sticky__note-icon {
  position: absolute;
  right: -3rem;
  bottom: 1rem;
  height: 6rem;
  width: 6rem;
}

.naira__circle-icon {
  position: absolute;
  top: -2.8rem;
  right: 1rem;
  width: 5rem;
}

.round__up-plane--two {
  position: absolute;
  bottom: -0.6rem;
  left: -1.9rem;
  width: 5.8rem;
}

.plane__icon-img {
  width: 100%;
  height: auto;
}

.carton__box-icon {
  width: 8.7rem;
  position: absolute;
  right: -0.5rem;
  bottom: -1.1rem;
}

.round__up-boxes {
}

.round__up-first--boxes {
  display: flex;
  margin-bottom: 0.7rem;
  gap: 0.7rem;
  height: 9.3rem;
}
.round__up-second--boxes {
  display: flex;
  margin-bottom: 1.6rem;
  gap: 0.7rem;
  height: 9.2rem;
}

.round__up-box--one {
  position: relative;
  padding: 0.8rem;
  max-width: 10.4rem;
  background-color: #2b3990;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1.5rem;
}

.round__up-box--two {
  width: 17.8rem;
  background-color: #bfe7f7;
  position: relative;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1.5rem;
}

.round__up-box--three {
  width: 17.8rem;
  background-color: #f5bc18;
  position: relative;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 1.5rem;
}

.round__up-box--four {
  position: relative;
  padding: 0.8rem;
  width: 10.3rem;
  background-color: #2b3990;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1.5rem;
}

.round__up-main--text {
  font-size: 1.67rem;
  font-weight: 800;
  line-height: 2.67rem;
  text-align: left;
  color: #f8fbff;
  margin-bottom: 0 !important ;
}

.main__text-one {
  // font-size: 1.8rem;
  font-weight: 800;
  line-height: 1.5rem;
  text-align: left;
  color: #f8fbff;
  margin-bottom: 0 !important;
}

.round__up-sub--text {
  font-size: 1.9rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.9rem;
  text-align: right;
  margin-bottom: 0rem !important;
  color: #f8fbff;
}

.sub__text-three {
  font-size: 1.9rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.9rem;
  text-align: left;
  color: #2b3990;
}

.second__box-text {
  color: #2b3990;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.5rem;
  text-align: left;
}

.main__text-four {
  font-size: 1.1rem;
  width: 6.3rem;
  line-height: 1.1rem;
}

.sub__text-four {
  text-align: left;
  font-size: 1.4rem;
}

.go__to-service {
  display: flex;
  background-color: #bfe7f7;
  padding: 0.5rem 0.8rem;
  gap: 1.6rem;
  position: relative;
  align-items: center;
}

.go__to-service--label {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.5rem;
  text-align: left;
  margin-bottom: 0 !important;
  color: #2b3990;
  width: 8.5rem;
}

.go__to-service--text {
  font-size: 1.9rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.9rem;
  letter-spacing: -0.05em;
  text-align: left;
  margin-bottom: 0 !important;
  color: #2b3990;
}

.go__to-service--icon {
  width: 3.8rem;
  position: absolute;
  top: 1.5rem;
  right: 2rem;
}

.share__buttons-container {
  display: flex;
  position: absolute;
  right: 24px;
  width: 36rem;
  top: -8rem;
  border-radius: 0.8rem;
  background-color: #ffffff;
  padding: 1.6rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
}

@media screen and (min-width: 48rem) {
  .year__roundup-heading {
    font-size: 1.5rem;
  }

  .year__roundup-content {
    padding: 2rem;
  }

  .round__up-first--boxes {
    margin-bottom: 0.7rem;
    grid-gap: 0.7rem;
    gap: 0.7rem;
    height: 10.3rem;
  }

  .round__up-second--boxes {
    height: 11.2rem;
  }

  .round__up-box--one {
    padding: 0.8rem;
    padding-bottom: 1.3rem;
    max-width: 14.4rem;
  }

  .round__up-box--two {
    width: 20.8rem;
    background-color: #bfe7f7;
    position: relative;
    padding: 1.3rem;
  }

  .second__box-text {
    font-size: 2rem;
    line-height: 2rem;
  }

  .round__up-sub--text {
    font-size: 1.8rem;
  }

  .round__up-box--three {
    width: 21.8rem;
    padding: 1rem;
  }

  .sub__text-three {
    font-size: 2rem;
  }

  .round__up-box--four {
    width: 13.3rem;
  }

  .main__text-four {
    font-size: 1.7rem;
    width: 10.3rem;
    line-height: 1.7rem;
  }

  .naira__circle-icon {
    width: 6rem;
  }

  .round__up-plane--two {
    left: -2.9rem;
    width: 6.8rem;
  }
  .carton__box-icon {
    width: 12.7rem;
    bottom: -1.5rem;
  }

  .sticky__note-icon {
    right: -2rem;
    bottom: 1.5rem;
    width: 6.9rem;
  }

  .go__to-service {
    padding: 1rem;
    gap: 2rem;
  }

  .go__to-service--label {
    font-size: 2rem;
    width: 10.5rem;
    line-height: 2rem;
  }

  .go__to-service--text {
    font-size: 2.2rem;
  }

  .go__to-service--icon {
    width: 5.8rem;
  }
}
// @media screen and (min-width: 48rem) {
//   .round__up-text {
//     width: 20rem;
//     margin-bottom: 2rem;
//   }

//   .round__up-plane--one {
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 16rem;
//   }

//   .round__up-plane--two {
//     bottom: -1.6rem;
//     left: -3.5rem;
//     height: 7.5rem;
//     width: 10.3rem;
//   }

//   .naira__circle-icon {
//     position: absolute;
//     top: -4rem;
//     right: 1.5rem;
//     height: 7.7rem;
//     width: 7.8rem;
//   }

//   .carton__box-icon {
//     right: -1rem;
//     bottom: -2.2rem;
//     width: 17.8rem;
//   }

//   .sticky__note-icon {
//     position: absolute;
//     right: -3.4rem;
//     bottom: 1.5rem;
//     width: 10.9rem;
//     height: 10.5rem;
//   }

//   .go__to-service--icon {
//     width: 66.7rem;
//     height: 66.7rem;
//   }

//   .Year__roundup-container {
//     padding: 5rem 2rem;
//   }

//   .year__roundup-content {
//     background-color: #fff7e8;
//     position: relative;
//     padding: 4rem 6.3rem;
//   }

//   .round__up-text {
//     width: 26.4rem;
//   }

//   .round__up-boxes {
//   }

//   .round__up-first--boxes {
//     display: flex;
//     margin-bottom: 1.6rem;
//     gap: 1.3rem;
//     height: 16.4rem;
//   }
//   .round__up-second--boxes {
//     display: flex;
//     height: 16.4rem;
//     gap: 1.3rem;
//   }

//   .round__up-box--one {
//     position: relative;
//     padding: 1.4rem;
//     max-width: 18.4rem;
//     background-color: #2b3990;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     border-radius: 1.5rem;
//   }

//   .round__up-box--two {
//     width: 31.7rem;
//     max-width: 31.7rem;
//     background-color: #bfe7f7;
//     position: relative;
//     padding: 1.4rem;
//     border-radius: 1.5rem;
//   }

//   .round__up-box--three {
//     width: 31.7rem;
//     max-width: 31.7rem;
//     padding: 1.4rem;
//   }

//   .round__up-box--four {
//     padding: 1.4rem;
//     width: 18.4rem;
//   }

//   .round__up-main--text {
//     font-size: 2.67rem;
//     font-weight: 800;
//     line-height: 2.67rem;
//     text-align: left;
//     color: #f8fbff;
//   }

//   .round__up-sub--text {
//     font-size: 3.38rem;
//     font-style: italic;
//     font-weight: 400;
//     line-height: 3.38rem;
//     margin-bottom: 0rem !important;
//   }

//   .main__text-four {
//     font-size: 1.9rem;
//     font-weight: 800;
//     line-height: 1.9rem;
//     width: 11.1rem;
//   }

//   .sub__text-four {
//     font-size: 2.3rem;
//     font-style: italic;
//     font-weight: 400;
//     line-height: 2.3rem;
//   }

//   .second__box-text {
//     color: #2b3990;
//   }

//   .sub__text-four {
//     text-align: left;
//   }

//   .go__to-service {
//     gap: 3rem;
//     padding: 1rem 0 1rem 1.4rem;
//     border-radius: 2rem;
//   }

//   .go__to-service--label {
//     font-size: 2.7rem;
//     font-weight: 800;
//     line-height: 2.7rem;
//     width: 16rem;
//   }

//   .go__to-service--text {
//     font-size: 3.4rem;
//     font-style: italic;
//     font-weight: 400;
//     line-height: 3.4rem;
//     text-align: left;
//   }

//   .go__to-service--icon {
//     width: 6.7rem;
//     height: 6.7rem;
//     top: 2.5rem;
//     right: 3rem;
//   }
// }
