.recent-shipment-item {
  border: 1px solid #e2e4e8;
  border-radius: 8px;
  margin: 16px 0;
  padding: 16px;

  .recent-shipment-item-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin: 16px 0;
    gap: 1rem;
    @include respond(mini-mobile) {
      &.time {
        // flex-wrap: wrap;

        // gap: 10px;
        // check later

        flex-wrap: wrap;
        gap: 2.4rem;
      }
    }

    @media screen and (min-width: 48rem) {
      .recent-shipment-item-data {
        flex-direction: column;
        // justify-content: ;
      }
    }
  }

  .processing__time-and-prompt {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (min-width: 26rem) {
      flex-direction: row;
      gap: 2rem;
    }
    @media screen and (min-width: 62.5rem) {
      flex-direction: row;
      gap: 5rem;
    }
  }

  .update__tracking-no-prompt {
    background: rgba(239, 165, 22, 0.1);
    padding: 0.4rem 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    border-radius: 0.4rem;
  }

  .update__tracking-no-text {
    color: #efa516;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 19.6px;
    text-align: left;
    margin-bottom: 0 !important;
  }

  .sender-receiver {
    justify-content: flex-start !important;
    border-bottom: none !important;
    & > * {
      margin-right: 6% !important;
    }
  }

  .recent-shipment-tracking-id {
    margin-top: 0px !important;
  }

  .recent-shipment-bottom-border {
    border-bottom: 1px solid #e2e4e8;
    padding-bottom: 20px;
  }

  .shipment-item-label {
    font-size: 12px;
    color: #545859;
    text-transform: uppercase;
  }

  .recent-shipment-item-id {
    font-size: 16px;
    color: black;
  }

  .recent-shipment-item-status {
    padding: 4px 10px;
    border-radius: 16px;
    font-size: 12px;
    line-height: 16.2px;
    text-align: center;
  }

  .location {
    display: flex;
    align-items: center;
    font-size: 16px;

    img {
      width: 20px;
      height: 14px;
      margin-right: 8px;
      border-radius: 2px;
    }
  }

  .time {
    margin-bottom: 0px !important;
  }

  .time span {
    display: flex;
    align-items: center;

    img {
      margin-right: 3%;
    }
  }

  .recent-shipment-item-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 10px;
    width: 100%;
    flex-direction: column;

    @media screen and (min-width: 48rem) {
      width: fit-content;
      flex-direction: row;
    }
  }
  .pay-now {
    cursor: pointer;
    padding: 1.2rem;
    border-radius: 8px;
    background-color: #22428f;
    color: #ffffff;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    text-align: center;
    justify-content: center;

    @media screen and (min-width: 48rem) {
      min-width: 10rem;
    }
  }
  .ship-again {
    cursor: pointer;
    padding: 1.2rem;
    border: 1px solid #22428f;
    border-radius: 8px;
    background-color: #ffffff;
    color: #22428f;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    @media screen and (min-width: 48rem) {
      width: unset;
    }
  }

  .date-time-divider {
    font-size: 20px;
    font-weight: 600;
  }

  .shipment-date-time {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 19px;
  }

  @media screen and (max-width: 640px) {
    #show-on-tablet-and-above {
      display: none;
    }
    .sender-receiver {
      display: flex;
      align-items: center;
      justify-content: space-between !important;
      padding: 16px 0;
      overflow: hidden;
    }
    .content-mobile {
      padding-top: 16px;
    }
    .sender-receiver-mobile {
      margin: 0;
      padding: 0;
      border-bottom: 0;
    }
  }

  @media screen and (min-width: 640px) {
    #show-only-on-mobile {
      display: none;
    }

    .show-on-tablet-and-above {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e2e4e8;
    }

    .recent-shipment-tracking-id {
      margin: 0px;
    }

    .column-above-mobile {
      height: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 0px;
      border-bottom: none;
    }
  }

  .w-120 {
    width: 120px !important;
  }
}
