$tablet_minimum_index: 711px;
$tablet_minimum: 712px;
$tablet_maximum: 768px;
$desktop_minimum: 1024px;
$desktop_average: 1350px;

.services {
  width: 100%;
  padding: 35px 56px 0 56px;

  @include respond(500px) {
    padding: 35px 16px 50px 16px;
  }

  .blue-btn {
    background-color: #22428f;
    width: 100%;
    height: 48px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__heading-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include respond(1100px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .contact-sales {
      background-color: #ffd708;
      border: 1px solid #ffd708;
      color: #22428f;
      font-size: 14px;
      font-weight: 700;
      width: 150px;
      height: 46px;
      border-radius: 8px;
      text-align: center;
    }
  }
  .nav-wrap {
    width: fit-content;
    background-color: #eef1f8;
    padding: 5px 8px;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .pill-item {
      background-color: transparent;
      padding: 10px 40px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #545859;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;

      &.isActive {
        background-color: #fff;
        color: #22428f;
      }
    }

    @include respond(600px) {
      gap: 8px;
      .pill-item {
        padding: 0.8rem 1.5rem;
        font-size: 10px;
      }
    }
  }

  .card-group {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @include respond(1100px) {
      grid-template-columns: repeat(1, 1fr);

      gap: 10px;
    }
  }

  &__card-wrap {
    width: 100%;
    height: 438px;
    background-color: #eef1f8;
    padding: 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .img-content {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: flex-end;

      img {
        width: inherit;
        // height: inherit;
        object-fit: contain;
      }
    }

    .text-content {
      background-color: #fff;
      border-radius: 8px;
      padding: 1.6rem;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      min-height: 17.4rem;
      justify-content: space-around;

      h4 {
        font-size: 18px;
        color: #0f1414;
        font-weight: 800;
      }

      p {
        font-size: 14px;
        line-height: 22px;
        color: #545859;
        font-weight: 500;
      }

      .btn-wrap {
        display: flex;
        justify-content: space-between;

        .view-btn {
          border: #e2e4e8 solid 1px;
          background-color: #fff;
          color: #22428f;
          font-size: 12px;
          height: 36px;
          width: 48%;
          border-radius: 10px;
          font-weight: 600;
        }

        .ship-now {
          border: #22428f solid 1px;
          background-color: #22428f;
          color: #fff;
          font-size: 12px;
          height: 36px;
          width: 48%;
          border-radius: 10px;
          font-weight: 600;
        }
      }
    }
  }

  &__prompt {
    animation: fadeIn 1s;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1071;
    margin-top: 100px;
    display: none;
    overflow: scroll;
    outline: 0;
    width: 570px !important;
    height: fit-content;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    @include respond(1100px) {
      margin-top: 50px;
    }
    @include respond(650px) {
      width: 95% !important;
      margin-top: 50px;
    }

    &.fade {
      opacity: 0;
      transition: opacity 0.15s linear;
    }
    &.fade.show {
      opacity: 1;
      display: block;
    }

    &__wrap {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      padding: 20px 0;

      .heading-group {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e2e4e8;

        h4 {
          font-size: 20px;
          font-weight: 700;
        }
      }

      .service-wrap {
        border: 1px solid #e2e4e8;
        border-radius: 10px;
        padding: 20px;
        margin: 30px;
      }

      .service-list {
        padding-left: 0;
        list-style: none;

        li {
          font-size: 14px;
          font-weight: 500;
          color: #0f1414;
          display: flex;
          align-items: flex-start;
          margin-bottom: 20px;

          p {
            padding-left: 6px;
            line-height: 20px;
          }
        }
      }

      .nav-wrap {
        margin: 20px auto 0;
        width: 94%;
        background-color: #eef1f8;
        padding: 5px 8px;
        border-radius: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .pill-item {
          background-color: transparent;
          padding: 10px 40px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #545859;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;

          &.isActive {
            background-color: #fff;
            color: #22428f;
          }
        }

        @include respond(600px) {
          gap: 8px;
          .pill-item {
            padding: 8px 20px;
            font-size: 10px;
          }
        }
      }
    }

    &__backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #000;
      display: none;
      &.fade {
        opacity: 0;
        transition: opacity 0.15s linear;
      }
      &.fade.show {
        display: block;
        opacity: 0.5;
      }
    }
  }

  .get-help-form {
    &__contact-form {
      padding: 100px 0 100px 0;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      position: relative;

      &.blue-bg {
        background: #f6f9ff;
      }

      @include respond(1000px) {
        flex-direction: column;
        padding: 50px 0 50px 0;
      }

      .pointer-div {
        height: 200px;
        width: 200px;
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-50%, -40%);

        @include respond(1000px) {
          display: none;
        }
      }

      .lets-chat {
        width: 56%;

        @include respond(1000px) {
          width: 70%;
        }
        @include respond(600px) {
          width: 100%;
        }
        .heading {
          font-weight: 600;
          font-size: 40px;
          line-height: 50px;
          letter-spacing: -0.02em;
          color: #000000;

          text-align: left;
          @include respond($desktop_average) {
            font-size: 32px;
            line-height: 48px;
          }

          @include respond(600px) {
            font-size: 28px;
            line-height: 30px;
          }
        }
        .paragraph {
          font-size: 18px;
          color: #545859;
          line-height: 22px;
          font-weight: 500;
          text-align: left;

          @include respond(1100px) {
            font-size: 16px;
          }
        }

        .contact-support {
          border: #e2e4e8 1px solid;
          background-color: #fff;
          color: #22428f;
          width: fit-content;
          font-weight: 700;
          font-size: 20px;
          padding: 26px;
          border-radius: 12px;
        }
      }
      .form-container {
        width: 44%;
        margin: auto;

        @include respond(1000px) {
          width: 70%;
          margin: 50px 0 0;
        }
        @include respond(789px) {
          width: 100%;
        }
      }

      .form-row {
        display: flex;
        justify-content: space-between;
        gap: 2%;

        @include respond(1090px) {
          flex-direction: column;
        }
      }

      .form-group {
        width: 100%;
        margin-bottom: 20px;
      }

      & label {
        display: block;
        font-size: 1.4rem;
        margin-bottom: 1rem;
        color: black;
        font-weight: 400;
        text-align: left;
      }

      & input,
      & textarea {
        padding: 1.4rem;
        width: 100%;
        background-color: white;
        font-size: 1.5rem;
        color: #6b6b6b;
        border: 1px solid #e7e4e4;
        border-radius: 12px;
        padding: 1.5rem 0.7rem;

        &:focus {
          outline: none;
        }
      }

      button {
        border: 1px solid #22428f;
        padding: 26px 20px !important;
      }
    }
  }
}
