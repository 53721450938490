.verification {
  &.is-modal {
    width: 100%;
  }

  &__wrap {
    gap: 48px;
    display: flex;
    padding: 40px 6%;
    @include respond(550px) {
      padding: 40px 8px;
    }
    flex-direction: column;
    align-items: center;
  }

  &__header {
    align-self: center;
    &__action button {
      font-family: 'General Sans' !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 20px !important;
      line-height: 27px !important;
      text-transform: capitalize !important;
      color: #22428f !important;
      align-items: center;
      cursor: pointer;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 27px;
    text-align: center;
    gap: 32px;
    width: 100%;
    @include respond(550px) {
      padding: 0 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__header h1 {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    padding: 0 27px;
    color: #0f1414;
  }

  &__description p {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    padding: 0 19px;
    color: #545859;
  }

  &__form {
    > div > div {
      justify-content: space-between;
      gap: 24px !important;
    }
    &__input {
      max-width: unset !important;
      input {
        width: 82px !important;
        height: 82px !important;
        background: #ffffff !important;
        border: 1px solid #e2e4e8 !important;
        border-radius: 8px !important;
        box-sizing: border-box !important;
        box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05) !important;

        font-family: 'General Sans' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 48px !important;
        line-height: 24px !important;

        color: #22428f !important;
        &:focus {
          border: 1px solid #22428f !important;
        }
      }
      fieldset {
        display: none;
      }

      @include respond(550px) {
        gap: 12px !important;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      span,
      button {
        font-family: 'General Sans' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 140% !important;
        color: #545859 !important;
      }
      button {
        text-transform: capitalize !important;
        color: #22428f !important;
        cursor: pointer !important;
      }
    }
  }

  &__action {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
    gap: 20px;
    & > * {
      width: 80% !important;
    }
    @include respond(mobile) {
      flex-direction: column;
      & > * {
        width: 100% !important;
      }
    }
  }
}
