html {
  scroll-behavior: smooth !important;
}

.pagination {
  margin: 17px 71px 60px 0;
  align-self: flex-end;
  background: #f8fbff;
  border: 1px solid #eef1f8;
  border-radius: 7px;
  @include respond(mini-mobile) {
    align-self: center;
    margin-right: 0;
  }
  &__wrap {
    display: flex;
    align-items: center;
    padding: 12px;
    gap: 19.04px;
  }
  &__content * {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0f1414;
    @include respond(400px) {
      font-size: 12px;
    }
  }
  &__action {
    transform: scale(2);
    color: #22428f !important;
    &:disabled {
      color: #b7b7b7 !important;
    }
  }
  #pagination-action {
    padding: 0px;
    margin-left: 12px;
  }
}
