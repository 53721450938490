.new-shop-n-ship {
  &__prompt {
    animation: fadeIn 1s;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1071;
    margin-top: 105px;
    scroll-behavior: smooth;

    height: fit-content;
    display: none;
    overflow: scroll !important;
    outline: 0;
    width: 450px;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    @include respond(1200px) {
      margin-top: 80px;
    }

    @include respond(1000px) {
      height: 80vh;
      margin-top: 50px;
    }
    @include respond(mini-mobile) {
      width: 95%;
    }
    &.fade {
      opacity: 0;
      transition: opacity 0.15s linear;
    }
    &.fade.show {
      opacity: 1;
      display: block;
    }
    &__wrap {
      display: flex;
      flex-direction: column;
    }
    &__header {
      height: 66px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 12px 24px;
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #0f1414;
      }
    }
    &__body {
      scroll-behavior: smooth;
      overflow: auto;
      background: #f8fbff;
      padding: 25.5px 24px;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      @include respond(1200px) {
        max-height: 400px;
      }

      @include respond(mini-mobile) {
        max-height: 400px;
        padding: 16px;
      }
      &__wrap {
        display: flex;
        flex-direction: column;
        gap: 16px;
        @include respond(1200px) {
          padding-bottom: 30px;
        }
      }
      &__content p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        color: #0f1414;
      }
      &__options {
        &__wrap {
          display: flex;
          flex-direction: column;
          gap: 13px;
        }
        &__header span {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;
          color: #545859;
        }
        &__list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          &__item {
            background: #ffffff;
            border: 1px solid #e2e4e8;
            box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            transition: all 0.3s ease;
            cursor: pointer;
            &.active {
              box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12),
                0px 0px 0px 3px #d4e5ff;
              border: 2px solid #22428f;
              .new-shop-n-ship__prompt__body__options__list__item {
                &__name h3 {
                  color: #22428f;
                }
              }
            }
            &__wrap {
              padding: 24px;
              display: flex;
              flex-direction: column;
              gap: 7px;
              @include respond(mini-mobile) {
                padding: 16px;
              }
            }
            &__name h3 {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              text-transform: uppercase;
              color: #0f1414;
            }
            &__address span {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #545859;
            }
          }
        }
        &__notice {
          border-radius: 8px;
          background: #e1f9ff;
          &__wrap {
            display: flex;
            padding: 24px;
            gap: 8px;
            p {
              color: #416eb2;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%;
            }
          }
        }
      }
    }
    &__footer {
      &__wrap {
        padding: 20px 24px;
        @include respond(mini-mobile) {
          padding: 16px;
        }
      }
      &__actions {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 8px;
        button {
          box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
          border-radius: 4px;
          border: 0;
          padding: 9px 24px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 140%;
          &:first-child {
            background: #ffffff;
            border: 1px solid #e2e4e8;
            color: #22428f;
          }
          &:last-child {
            background-color: #22428f;
            color: #ffffff;
            &:disabled {
              opacity: 0.4;
              background: #b7b7b7;
            }
          }
        }
      }
    }
    &__backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #000;
      display: none;
      &.fade {
        opacity: 0;
        transition: opacity 0.15s linear;
      }
      &.fade.show {
        display: block;
        opacity: 0.5;
      }
    }
  }
  &__modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: scroll;
    outline: 0;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    animation: fadeOut 0.5s;
    &.show {
      animation: fadeIn 0.5s;
      display: block;
    }
    &__wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    &__header {
      &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 29px 17.4%;
        @include respond(mini-mobile) {
          padding: 20px 24px;
        }
      }
      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #0f1414;
      }
      button {
        transform: scale(2);
        color: #22428f !important;
      }
      border-bottom: 1px solid #eef1f8;
      @include respond(mini-mobile) {
        border-bottom: unset;
      }
    }
    &__body {
      height: 100%;
      &__wrap {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: unset;
      }
      &__label {
        padding: 60px 7.7% 0 0;
        height: 100%;
        border-right: 1px solid #eef1f8;
        @include respond(smaller) {
          display: none;
        }
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;
          color: #22428f;
          background: #f8fbff;
          border-radius: 8px;
          padding: 10px;
        }
      }
      &__content {
        height: 100%;
        flex: 2;
        @include respond(mini-mobile) {
          // padding-top: 24px;
        }
        &__welcome-guide {
          max-width: 500px;
          margin: 0 auto;

          &__shipping-guide {
            .address-container {
              margin: 40px 0;
              @include respond(600px) {
                margin: 30px 0;
              }
            }
            label {
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
              color: #545859;
              margin-bottom: 8px;
              text-transform: none;
            }

            h2 {
              margin-top: 0;
            }

            .select-wrap {
              position: relative;
              display: block;
            }

            .selected-option {
              background: white;
              height: 48px;
              width: 100%;
              padding: 0 10px;
              margin-bottom: 5px;
              border: 1px solid #ccc;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-radius: 8px;
              cursor: pointer;
              box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

              p {
                margin-bottom: 0;
                font-size: 13px;
                color: #545859;
              }
            }

            .options {
              position: absolute;
              top: 100%;
              left: 0;
              background: white;
              border: 1px solid #ccc;
              border-radius: 6px;
              width: 100%;
              margin-top: 10px;
              font-size: 12px;
              font-weight: 500;
              padding: 10px;
              z-index: 90;
              box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
            }

            .options > div {
              padding: 8px 16px;
              cursor: pointer;
            }

            .options > div:hover {
              background: #f1f1f1;
            }

            .address-info-wrap {
              border: 1px solid #ccc;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              height: 60px;
              padding: 6px 10px;
              box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
            }

            .address-info {
              font-size: 12px;
              color: #545859;
              line-height: 16px;
            }

            small {
              padding-top: 20px;
              font-style: normal;
              font-size: 11.4px;
              color: #545859;
              line-height: 16px;
            }
          }

          &__title {
            color: #22428f;
            font-size: 48px;
            line-height: 54px;
            font-weight: 800;
            letter-spacing: -0.8px;

            @include respond(600px) {
              font-size: 38px;
              line-height: 46px;
              margin-top: -40px;
            }
          }

          &__description {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            color: #545859;
          }
          &__buttons {
            display: block;
            margin: 54px 0 100px 0;

            a {
              text-decoration: underline;
              color: #22428f;
              font-size: 14px;
              font-weight: 500;
              @include respond(600px) {
                text-align: right;
              }
            }
            @include respond(600px) {
              margin: 10px 0 50px 0;
            }
          }
        }

        &__how-it-works {
          &__description-heading {
            font-size: 20px;
            font-weight: bold;
            color: #22428f;
            @include respond(600px) {
              font-size: 15px;
            }
          }
          &__description {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            color: #545859;
            > .blue {
              font-weight: bold;
              color: #22428f;
            }
            @include respond(600px) {
              font-size: 15px;
            }
          }
        }
      }
      &__form {
        margin-bottom: 32px;
        &__item {
          display: flex;
          flex-direction: column;
          &__label {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #1d1d1d;
            margin-bottom: 20px;
          }
          &__input {
            transition: all 0.4s ease !important;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: #545859;
            margin-bottom: 8px;
            text-transform: none;

            label {
              font-style: normal;
              font-weight: 500 !important;
              font-size: 14px !important;
              line-height: 20px !important;
              color: #0f1414;
              margin-bottom: 4px !important;
              text-transform: none !important;
              span {
                color: red;
              }
            }
          }
          &__buttons {
            display: flex;
            align-items: center;
            gap: 16px;
            padding-bottom: 100px !important;
            margin: 64px 0 0 0 !important;
          }
          &__button {
            width: 100%;
            background: #ffffff;
            border: 1px solid #e2e4e8;
            box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            padding: 14px 24px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            color: #22428f;
            text-align: center;
            cursor: pointer;
            &.disabled {
              opacity: 0.4;
            }
          }
        }
      }

      #input-control {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding: 16px 0px 0px 10px;

        label {
          margin-bottom: 10px;
          font-style: normal;
          font-weight: 500 !important;
          font-size: 14px !important;
          line-height: 20px !important;
          color: #0f1414;
          text-transform: none !important;
          font-family: inherit;
          width: fit-content;

          span {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: #545859;
            text-transform: none;
          }
        }

        .radio-group {
          display: flex;
          flex-direction: column;
        }

        .radio-group-option {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 15px 0px 0px 0px !important;
          cursor: pointer;
          background: #ffffff;
          padding: 8px 10px;
          border: 1px solid #e2e4e8;
          box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          transition: all 0.3s ease;
          cursor: pointer;
          &.active {
            box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12),
              0px 0px 0px 3px #d4e5ff;
            border: 2px solid #22428f;
            .new-shop-n-ship__prompt__body__options__list__item {
              &__name h3 {
                color: #22428f;
              }
            }
          }
          span {
            margin-left: 10px;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            flex-direction: column;

            strong {
              padding-bottom: 3px;
            }

            @include respond(500px) {
              font-size: 12px;
            }
          }
        }

        input[type='radio'] {
          cursor: pointer;
        }

        input[type='file'] {
          margin-top: 10px;
        }

        small {
          display: block;
          color: #d32f2f;
          margin: 5px;
        }

        #upload-button {
          display: flex;
          flex-direction: column;
          margin-top: 10px;

          .filename {
            margin-top: 10px;
            font-size: 1.5rem;
          }
          label {
            padding: 9px 7px;
            margin-right: 1rem;
            border-radius: 20px;
            border: 2px solid #22428f;
            color: #22428f;
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            width: fit-content;
            text-transform: capitalize;
          }
        }
      }
      .agreement {
        display: flex;
        align-items: center;
        padding: 10px 0px;
        gap: 8px;
        font-weight: 500 !important;
        input {
          cursor: pointer;
          transform: scale(1.5);
        }
        span {
          padding-left: 8px;
          font-size: 14px;
        }
      }
    }
  }

  &__notice {
    width: 100%;
    background-color: rgba(239, 165, 22, 0.1);
    display: flex;
    text-align: left;
    align-items: flex-start;
    padding: 30px;
    border-radius: 8px;
    margin-top: 2rem;

    img {
      padding-right: 10px;
    }

    p {
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 2.2rem;
      color: #0f1414;
      margin: 0;
    }

    @include respond(600px) {
      padding: 20px 10px;

      p {
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }
  }
}

.name-on-package {
  border: 1px solid #ccc !important;
  // border-bottom: none !important;
  font-size: 1.5rem !important;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  // border: 0;
  box-sizing: content-box;
  background: none;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 10px 14px !important;
  border-radius: 1rem !important;
  background-color: #fff !important;
}

.name-on-package::before {
  border-bottom: none !important;
}
.name-on-package::after {
  border-bottom: none !important;
}
