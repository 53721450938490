@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800;900&display=swap');

@import './styles/abstracts/functions';
@import './styles/abstracts/mixins';
@import './styles/abstracts/variables';

@import './styles/base/animation';
@import './styles/base/base';
@import './styles/base/typography';
@import './styles/base/utilities';

@import './styles/components/header';
@import './styles/components/inputGroup';
@import './styles/components/inputGroup2';
@import './styles/components/submit-button';
@import './styles/components/password-control';
@import './styles/components/intro-text';
@import './styles/components/error';
@import './styles/components/custom-spinner';
@import './styles/components/empty-list';
@import './styles/components/busy-overlay';
@import './styles/components/delete-overlay';
@import './styles/components/feedback-overlay';
@import './styles/components/file-upload';
@import './styles/components/terms-overlay';
@import './styles/components/contact-support';
@import './styles/components/get-quotes-form';
@import './styles/components/recent-shipment';
@import './styles/components/shipment-status';
@import './styles/components/pagination';
@import './styles/components/booking-successful';
@import './styles/components/book-shipment-modal';
@import './styles/components/share-buttons';
@import './styles/components/in-app-modal';
@import './styles/components/payment-method-prompt';
@import './styles/components/pending-shopnship';
@import './styles/components/tracking-id-update-modal';
@import './styles/components/how-it-works';
@import './styles/components/survey-modal';
@import './styles/components/service-closure-banner';
@import './styles/components/service-closure-modal';
@import './styles/pages/authentication';
@import './styles/pages/verification';
@import './styles/pages/verification-modal';
@import './styles/pages/forgot-password';
@import './styles/pages/reset-password';
@import './styles/pages/verify-email';
@import './styles/pages/dashboard';
@import './styles/pages/tracking';
@import './styles/pages/notfound';
@import './styles/pages/maintenance';
@import './styles/pages/shipment-bonus';
@import './styles/pages/terms-and-conditions';
@import './styles/pages/sender-info';
@import './styles/pages/multiple-receiver-info';
@import './styles/pages/item-description';
@import './styles/pages/delivery-options';
@import './styles/pages/profile';
@import './styles/pages/order-summary';
@import './styles/pages/shop-n-ship';
@import './styles/pages/get-quotes';
@import './styles/pages/new-signup';
@import './styles/pages/login';
@import './styles/pages/new-wallet';
@import './styles/pages/new-referral';
@import './styles/pages/new-addresses';
@import './styles/pages/new-shop-n-ship';
@import './styles/pages/new-tracking';
@import './styles/pages/new-get-quote';
@import './styles/pages/sea-freight';
@import './styles/pages/faq';
@import './styles/pages/services';
@import './styles/pages/config';
@import './styles/layout/requests';
@import './styles/layout/quote-summary';
@import './styles/layout/payment-successful';
@import './styles/layout/content-layout';
@import './styles/layout/wrapper';
@import './styles/layout/new-shipping-steps-layout';
@import './styles/pages/past-shipment-list';
@import './styles/pages/past-shipment-details-modal';
@import './styles/pages/round-up';
@import './styles/pages/resources';
@import './styles/pages/vib';

@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  // font-family: 'Montserrat', 'General Sans', sans-serif;
  font-family: 'General Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'General Sans', sans-serif !important;
}

.notyf__message {
  line-height: 150% !important;
  font-size: 16px !important;
  font-weight: bold;
}

div[role='presentation'] .MuiPaper-root .MuiMenuItem-root {
  font-size: 1.5rem;
}

.volumetric-weight {
  width: 100%;
  margin-top: 30px;
}

.switch-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    font-size: 16px;
    font-weight: 500;
    color: #545859;
    margin-bottom: 0;

    @include respond(mini-mobile) {
      font-size: 14px;
    }
  }
}

.switch {
  margin-left: 30px;
  width: 42px;
  height: 20px;
  background-color: #979797;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
}

.switch.active {
  background-color: #0a16a7;
}

.circle {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.switch.active .circle {
  transform: translateX(17px);
}

.input-switch {
  width: 40px;
  height: 20px;
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

.input-switch.active {
  background-color: #0a16a7;
}

.switch-slider {
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  transition: transform 0.3s;
}

.calculator-img-container {
  position: relative;
  height: 24rem;
  margin-top: -30px;
  @include respond(1380px) {
    margin-top: 0;
  }
}

.new-badge {
  background-color: #e5edff !important;
  border-radius: 50px;
  color: #22428f !important;
  padding: 8px 15px !important;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;

  @include respond(600px) {
    padding: 10px 12px;
    font-size: 12px;
  }
}

.tag-text {
  font-size: 1.2rem;
  padding-left: 0.8rem;
  margin-bottom: 0;
  font-weight: 600;
  white-space: nowrap;

  p {
    margin-bottom: 0rem !important;
    text-wrap: wrap;
  }

  @include respond(mini-mobile) {
    white-space: wrap;
  }
}

.how-it-works {
  cursor: pointer;
  position: relative;
  margin-top: 20px;
  border: 1px solid #e2e4e8;
  border-radius: 50px;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #22428f;
  text-decoration: none;
  background-color: #fff;
  text-align: center;
  padding: 10px 15px;
}

.absolute-position {
  position: absolute;
  left: -24px;
  z-index: 100px;
  width: fit-content;
}
.top-style {
  top: 20px;
}

.bottom-style {
  bottom: 10px;
}
.how-guide {
  background-color: #fff;
  color: #545859;
  width: 490px;
  border-radius: 12px;
  padding: 20px;
  border: 1px solid #e2e4e8;
  white-space: nowrap;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);

  @include respond(500px) {
    width: 380px;
    padding: 15px;
    white-space: break-spaces;
  }
  @include respond(400px) {
    width: 300px;
    padding: 8px;
  }
}
.rate-border {
  border-right: 1px solid #e2e4e8;
  @include respond(500px) {
    display: none;
  }
}
.budget-country-lists {
  position: relative;
  border: 1px solid #e2e4e8;
  border-radius: 50px;
  width: 300px;
  margin: 10px 0;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // cursor: pointer;
}
.country-popup {
  position: absolute;
  margin-top: 30px;
  z-index: 50;
  top: 50px;
  left: 0;
  background: #fff;
  border: 1px solid #e2e4e8;
  border-radius: 6px;
  box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.03);
  padding: 20px;
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

.rates-wrap {
  border: 1px solid #e2e4e8;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;

  @include respond(500px) {
    display: block;
  }
}

.resource-page-wrap {
  width: 70%;
  margin: 0 auto;

  @include respond(1100px) {
    width: 80%;
  }

  @include respond(800px) {
    width: 100%;
  }
}
.get-help-button {
  background-color: #e5edff;
  border: 1px solid #e5edff;
  border-radius: 8px;
  color: #22428f;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 10px 14px;
  height: 40px;

  img {
    padding-left: 4px;
  }
}

.alert-rate {
  color: #f05d3d !important;
}

.fill-radio {
  fill: #f05d3d !important;
}

.alert-rate-border {
  border: 2px solid #f05d3d !important;
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12),
    0px 0px 0px 3px rgba(240, 93, 61, 0.1) !important;
}
.x-cancel {
  span {
    transform-origin: 8px 1px;
    height: 2.5px;
    width: 20px;
    border-radius: 8px;
    display: block;
    background-color: #22428f;

    &:first-child {
      transform: rotate(45deg);
    }
    &:last-child {
      transform: rotate(-45deg);
    }
  }
}
