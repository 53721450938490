.survey-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background-color: #000000a1;
  z-index: 100;
  animation: fadeIn 1s;
  transition: all 0.4s 0.2s;

  &__close-icon {
    height: 5rem;
    width: 5rem;
    cursor: pointer;
    transition: all 0.4s 0.2s;
    opacity: 0;
    animation: fadeIn 1s ease-in forwards;
    animation-delay: 4s;
  }
}

.survey-modal__img-container {
  height: 40rem;
  &__image {
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
  }
}

.show-modal {
  display: flex;
}

.close-modal {
  display: none;
}

@media screen and (min-width: 48rem) {
  .survey-modal__img-container {
    height: 50rem;
  }
}
@media screen and (min-width: 90rem) {
  .campaign-modal {
    padding: 30rem;

    &__image-container {
      height: 70rem;
      padding: 5rem;
    }

    &__close-icon {
      height: 7rem;
      width: 7rem;
    }
  }

  .survey-modal__img-container {
    height: 60rem;
  }
}
