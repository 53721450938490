.verify-email {
  &__wrapper {
    text-align: center;
    @include dashboardWrapper;

    & h1 {
      color: var(--color-blue-dark);
      font-weight: 800;
      font-size: calc(1.8rem + 1vw);
    }

    & p {
      width: 40rem;
      margin: auto;
      color: #3d5276;
      font-size: 1.5rem;
      font-weight: 500;
      align-self: center;
      margin-top: 3rem;
    }
  }
}
