.request {
  &__wrapper {
    @include dashboardWrapper;
    align-items: center;
    margin-top: 2rem;
  }

  &__form {
    margin-top: 4rem;
    padding-bottom: 4rem;
    &__node {
      &__text {
        margin: 1rem 0 0.5rem 0;
        display: block;
        font-weight: 600;
        font-size: 1.1rem;
        color: var(--color-blue-dark);
        text-transform: uppercase;

        @include respond(tablet) {
          font-size: 1.5rem;
        }

        & img {
          margin-right: 0.5rem;
        }

        &--destination {
          color: #74b6f7;
        }
      }
      padding: 1rem;
      margin-left: 0.5rem;

      &--origin {
        background-image: linear-gradient(
          var(--color-blue-dark) 33%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: left;
        background-size: 2px 15px;
        background-repeat: repeat-y;
      }

      &__address {
        padding-left: 1.5rem;
        & h4 {
          color: #3d5276;
          font-weight: 700;
          font-size: 1.3rem;

          @include respond(tablet) {
            font-size: 1.6rem;
          }
        }

        & p {
          font-weight: 500;
          color: #5e6e87;
          font-size: 1.2rem;

          @include respond(tablet) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
