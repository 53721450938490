.input-group--wrapper {
  margin-bottom: auto !important;
  margin-top: auto !important;
  display: flex;
  flex-direction: column;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}
.mt-5 {
  margin-top: 5rem !important;
}
.mt-6 {
  margin-top: 6rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}
.ml-3 {
  margin-left: 3rem !important;
}
.ml-4 {
  margin-left: 4rem !important;
}
.ml-5 {
  margin-left: 5rem !important;
}
.ml-6 {
  margin-left: 6rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-4 {
  margin-bottom: 4rem !important;
}
.mb-5 {
  margin-bottom: 5rem !important;
}
.mb-6 {
  margin-bottom: 6rem !important;
}

button.loading {
  height: 5.2rem !important;
  width: 5.2rem !important;
  padding: 0 !important;
  margin: auto !important;
  border-radius: 50% !important;
  align-self: center !important;
  background-color: transparent !important;
  border: 0.8rem solid rgb(220, 220, 220) !important;
  border-top: 0.8rem solid var(--color-blue-dark) !important;
  animation: spin 1s 0.3s infinite !important;
  margin-top: 1rem !important;
  margin: auto !important;
  display: block !important;

  & span {
    display: none;
  }
}

.disable-element {
  pointer-events: none;
  opacity: 0.4;
}
