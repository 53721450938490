.payment-successful {
  @include dashboardWrapper;
  text-align: center;
  margin-top: 10rem;

  & h1 {
    color: var(--color-blue-dark);
    font-weight: 800;
    font-size: calc(1.8rem + 1vw);
    margin-top: 4rem;
  }

  & p {
    width: 40rem;
    margin: auto;
    color: #3d5276;
    font-size: 1.5rem;
    font-weight: 500;
    align-self: center;
  }
}

.sea-payment-successful {
  width: 600px;
  margin: 0 auto;
  border: 1px solid #e2e4e8;
  border-radius: 8px;
  padding: 20px;

  text-align: center;
  margin-top: 10rem;

  & h1 {
    color: var(--color-blue-dark);
    font-weight: 800;
    font-size: calc(1.8rem + 1vw);
    margin-top: 4rem;
  }

  & p {
    width: 100%;
    margin: 10px auto;
    color: #3d5276;
    font-size: 1.5rem;
    font-weight: 500;
    align-self: center;
  }
}
