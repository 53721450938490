.notfound {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;

  .img {
    width: 500px;

    @include respond(mobile) {
      width: 80vw;
    }
  }

  .button {
    margin-top: 40px;
  }
}
