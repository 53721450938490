.book-shipment-modal-content-container {
  width: 650px !important;
  gap: 100px !important;
  display: flex !important;
  overflow: auto !important;
  align-items: baseline !important;
  justify-content: space-between !important;
  .book-shipment-modal-contents {
    min-width: 550px !important;
  }
  @include respond(600px) {
    width: 90% !important;
    gap: 75px !important;
    margin: 100px 0 !important;
    flex-direction: column !important;
    .book-shipment-modal-contents {
      min-width: 95% !important;
    }
  }
}

.book-shipment-modal {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
  transition: all 0.4s 0.2s;
  animation: fadeIn 1s;
}

#close-button {
  font-size: 2em;
  color: #22428f;
}

.book-shipment-modal-close-button {
  display: flex;
  padding: 3%;
  justify-content: flex-end;

  .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    padding: 4px;
  }

  p {
    font-size: 1.5rem;
    font-weight: 500;
    color: #ffffff;
    margin: 0px;
  }
}

.book-shipment-modal-content-container {
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 280px) {
    width: 90%;
    top: 50px;
  }

  @media screen and (min-width: 320px) {
    width: 90%;
  }

  @media screen and (min-width: 425px) {
    width: 90%;
  }

  @media screen and (min-width: 640px) {
    width: 550px;
  }
}

.book-shipment-modal-header {
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5% 0 5%;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin: 0px;
    padding: 0 5%;
  }
}

.book-shipment-modal-footer {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5%;

  .book-shipment-modal-button {
    width: 120px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    margin-left: 15px;
    padding: 18px 24px;
    cursor: pointer;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 16.8px;
      margin: 0;
    }
  }

  .shipment-option-footer {
    span {
      text-align: right;
    }
    justify-content: flex-end !important;
  }

  .book-shipment-modal-next-button {
    background-color: #b7b7b7;
    border: 1px solid #e2e4e8;
    color: #ffffff;
    pointer-events: none;
  }

  .book-shipment-modal-cancel-button {
    background-color: #ffffff;
    border: 1px solid #e2e4e8;
    color: #22428f;
  }

  .book-shipment-close-modal {
    display: none;
  }

  .book-shipment-modal-next-button-active {
    background-color: #22428f;
    pointer-events: auto;
  }
}

.book-shipment-modal-contents {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;

  .book-shipment-content-title {
    color: #545859;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    margin: 0px 0px 14px 0px;
  }

  @media screen and (min-width: 320px) {
    width: 100%;
    min-width: 170px;
  }
}

.book-shipment-modal-content {
  width: 100%;
  padding: 5%;
  background-color: #f8fbff;
  animation: fadeIn 0.9s;
}

.book-shipment-content-item {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 16px;
  margin: 12px 0px;
  border-radius: 8px;
  border: 1px solid #e2e4e8;
  box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.4s ease;
  p {
    color: #545859;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    margin: 0px;
  }

  @media screen and (min-width: 320px) {
    padding: 12px;
    p {
      font-size: 12px;
    }
  }

  @media screen and (min-width: 375px) {
    padding: 12px;
    p {
      font-size: 14px;
    }
  }

  @media screen and (min-width: 640px) {
    // padding: 12px;
    p {
      font-size: 16px;
    }
  }
}

.book-shipment-content-item-title {
  text-transform: uppercase;
  color: #0f1414;
  font-style: normal;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 19px;
}

.book-shipment-content-item-selected {
  border: 2px solid #22428f;
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 3px #d4e5ff;

  .book-shipment-content-item-title {
    color: #22428f;
  }
}

.book-shipment-method-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.book-shipment-method-title-container p:nth-child(2) {
  color: #10c54e;
  padding: 8px 10px;
  background-color: rgba(16, 197, 78, 0.1);
  border-radius: 16px;
  font-size: 14px;
  margin: 0px;
}
.PrivatePickersSlideTransition-root .MuiPickersDay-root {
  font-size: 1.8rem;
}
.select-pickup-date {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0f1414;
  }

  .MuiButtonBase-root {
    transform: scale(1.5);
  }

  .MuiOutlinedInput-input {
    font-size: 18px;
    background-color: #ffffff;
    padding: 10.5px 14px;
  }

  @media screen and (min-width: 640px) {
    .MuiOutlinedInput-input {
      padding: 16.5px 14px;
    }
  }
}

.show-modal {
  display: flex;
}

.close-modal {
  display: none;
}

body {
  .css-m1gykc.Mui-selected,
  .css-1v994a0,
  .css-m1gykc,
  .css-7kykdr-MuiButtonBase-root-MuiIconButton-root,
  .css-1w13o7u-MuiTypography-root,
  .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root,
  .css-195y93z-MuiButtonBase-root-MuiPickersDay-root {
    font-size: 14px;
  }
  .css-f4950f-MuiSvgIcon-root,
  .css-fd2y78-MuiSvgIcon-root {
    font-size: 24px;
  }

  .css-i4bv87-MuiSvgIcon-root {
    font-size: 20px;
  }
}
