.faq {
  width: 100%;
  animation: fadeIn 0.5s;
  &__wrap {
    padding: 56px 0 56px 56px;
    @include respond(769px) {
      padding: 100px 16px;
    }
  }
  &__questions {
    .shopnship-faq {
      width: 600px;
    }
    @include respond(769px) {
      .shopnship-faq {
        width: 100%;
      }
    }
    &__wrap {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
    }
  }
  &__question {
    width: 600px;
    color: unset !important;
    box-shadow: unset !important;
    @include respond(tablet) {
      width: 100%;
    }
    &::before {
      display: none;
    }
    &__button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      border: 1px solid #eeeeee !important;
      border-radius: 8px !important;
      padding: 12px 24px !important;
      svg {
        transform: scale(2);
        color: #22428f !important;
      }
    }
    &__header {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #0f1414;
      margin: 0;
    }
    &.active {
      background: #f6fafb;
      border-radius: 8px;

      .faq__question__button {
        background-color: #f6fafb;
        border: 0 !important;
        padding: 4px 24px 0 24px !important;
        .MuiAccordionSummary-content {
          margin-bottom: 8px !important;
        }
      }
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #22428f;
      }
      .faq__question__body {
        padding: 0 72px 24px 24px;
        p {
          margin: 0;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #545859;
        }
        @include respond(mobile) {
          padding: 0 20px 24px 24px;
          p {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
    &.shopnship {
      .shopnship-paragraph {
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 28px !important;
        color: #545859 !important;
        > .bold {
          font-weight: bolder !important;
        }
        @include respond(mobile) {
          font-size: 14px !important;
          line-height: 24px !important;
        }
      }
      .faq__question__button {
        svg {
          transform: scale(1.5);
        }
      }
    }
  }
}
