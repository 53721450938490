.item-description {
  .form-transition {
    transition: opacity 0.5s ease;
  }

  .add-box-style {
    border-bottom: 1px solid #e2e4e8;
    flex-grow: 1;
  }

  &__new-box {
    position: relative;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: #22428f dashed 1px;
    border-radius: 8px;
    margin: 20px;
    cursor: pointer;
    width: 160px;
    height: 160px;

    p {
      color: #22428f;
      font-size: 14px;
    }

    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   z-index: -1; /* Ensure it's behind the container content */
    //   background-image: repeating-linear-gradient(
    //     to right,
    //     #22428f 0,
    //     #22428f 1px,
    //     /* Adjust the thickness of the border */ #22428f 1px,
    //     /* Adjust the thickness of the border */ #22428f calc(100% - 1px),
    //     /* Adjust the thickness of the border */ #22428f calc(100% - 1px)
    //       /* Adjust the thickness of the border */
    //   );
    // }
  }

  &__box-model {
    cursor: pointer;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &.active {
      border-top: 1px solid #e2e4e8;
      border-right: 1px solid #e2e4e8;
      border-left: 1px solid #e2e4e8;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &.active::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: repeating-linear-gradient(
        to right,
        #e2e4e8 0,
        #e2e4e8 calc(100% / 20),
        transparent calc(100% / 20),
        transparent calc(200% / 20)
      );
    }

    &:not(.active) {
      border-bottom: 1px solid #e2e4e8; /* Or any other color for inactive boxes */
    }
  }
  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1d1d1d;
    margin-bottom: 6px;
  }
  &__mains {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__main {
    // border: 1px solid #e2e4e8;
    border-left: 1px solid #e2e4e8;
    border-right: 1px solid #e2e4e8;
    border-bottom: 1px solid #e2e4e8;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    // border-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #fff;
    &__wrap {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      @include respond(mini-mobile) {
        padding: 16px;
      }
    }

    &__weight-wrapper {
      width: 100%;
      margin-top: 30px;
      padding: 32px 24px;
      gap: 16px;
      @include respond(mini-mobile) {
        padding: 16px;
      }

      .input {
        margin-bottom: 30px;
      }

      label {
        margin-bottom: 8px !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 22px !important;
        color: #0f1414;
      }
    }
    &__action {
      align-self: flex-end;
      button {
        cursor: pointer;
        background-color: rgba(240, 93, 61, 0.05);
        border-radius: 36px;
        justify-content: space-between !important;
        padding: 8px 10px !important;
        gap: 6px;
        &:hover {
          background-color: rgba(240, 93, 61, 0.05) !important;
        }
        span {
          font-style: normal !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 19px !important;
          color: #f05d3d !important;
          text-transform: none !important;
        }
        svg path {
          fill: #f05d3d;
        }
      }
    }
    &__grid {
      display: flex;
      flex-direction: column;
      .category-helper {
        align-self: flex-start !important;
        margin: 0;
        margin-bottom: 11px;

        @include respond(1199px) {
          align-self: flex-end !important;
          margin-bottom: 0 !important;
        }
      }
      .web-alert {
        display: block;

        @include respond(1199px) {
          display: none;
        }
      }
      .mobile-alert {
        display: none;
        margin-left: 10px;

        @include respond(1199px) {
          display: block;
        }
      }
      &__helper {
        position: relative;
        cursor: pointer;
        display: block;
        align-self: flex-end;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 18px;
        color: #22428f;
        margin: 16px 0 8px 0;
        margin-bottom: -11px;

        @include respond(smaller) {
          transform: translateY(105px);
          margin-bottom: 5px;
        }
      }
    }
    &__input {
      label {
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 20px !important;
        color: #0f1414 !important;
        margin-bottom: 4px !important;
      }
      textarea {
        line-height: 24px;
      }
    }

    &__alert {
      svg,
      .MuiAlert-icon {
        display: none;
      }

      aside {
        gap: 8px;
        display: flex;
        font-weight: 500;
        align-items: flex-start;

        &::before {
          content: url('../../assets/svg/info-blue.svg');
          display: block;
          width: 1em;
          height: 1em;
          margin-top: 4px;
        }
      }
    }
  }
  &__action {
    align-self: flex-start;
    &.switcher {
      margin-bottom: -23px;
      @include respond(400px) {
        margin-bottom: 10px;
      }
    }
    button {
      cursor: pointer;
      background-color: #f8fbff;
      border-radius: 16px;
      justify-content: space-between !important;
      padding: 8px 10px !important;
      gap: 6px;
      &:hover {
        background-color: #f8fbff !important;
      }
      span {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 19px !important;
        color: #22428f !important;
        text-transform: none !important;
      }
      svg path {
        fill: #22428f;
      }
      &:disabled {
        opacity: 0.4;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 74px;

    @include respond(700px) {
      flex-direction: column-reverse;
    }
    &__action {
      width: 100%;
      background: #ffffff;
      border: 1px solid #e2e4e8;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 14px 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #22428f;
      text-align: center;
      cursor: pointer;
    }
  }
}

.data__description-text {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #737c7f;
  margin-bottom: 0;
  // margin-top: .4rem;
}

.data__validity-info--box {
  display: flex;
  background-color: #e6f2ff;
  padding: 0.8rem 1rem;
  gap: 0.5rem;
  border-radius: 0.8rem;
  margin-bottom: 1.2rem;
}

.data__validity-info {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 18.2px;
  text-align: left;
  color: #22428f;
  margin-bottom: 0 !important;
}
