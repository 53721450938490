.multiple-receiver-info {
  padding-bottom: 31px;
  .new-addresses__list__item {
    width: 100%;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #0f1414;
  }
  &__items {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__item {
    border: 1px solid #e2e4e8;
    border-radius: 8px;
    &__wrap {
      display: flex;
      flex-direction: column;
      padding: 24px;
      @include respond(mini-mobile) {
        padding: 16px;
      }
    }
    &__selection {
      background: #eeeff2;
      border-radius: 12px;
      &__wrap {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 4px;
      }
      &__option {
        flex: 1;
        display: flex;
        justify-content: center;
        padding: 8px 0;
        cursor: pointer;
        &.active {
          background: #ffffff;
          box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
        }
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          color: #0f1d40;
        }
      }
    }
    &__action {
      cursor: pointer;
      background: #e7edfc !important;
      border-radius: 8px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 140% !important;
      justify-content: center !important;
      color: #22428f !important;
      text-transform: none !important;
      padding: 12px 0 !important;
      &.Mui-disabled {
        opacity: 0.4;
      }
    }
  }
  &__action {
    cursor: pointer;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #22428f !important;
    align-self: flex-start !important;
    text-transform: none !important;
    gap: 13px;
    svg {
      transform: scale(1.5);
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    gap: 16px;
    &__action {
      cursor: pointer;
      width: 100%;
      background: #ffffff;
      border: 1px solid #e2e4e8;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 14px 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #22428f;
      text-align: center;
    }
  }
}
