.dashboard {
  .shipment-overview {
    border-radius: 8px;
    border: 1px solid var(--Topship-Colors-Input-Outline, #e2e4e8);
    margin-top: 24px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond(500px) {
        flex-direction: column;
        align-items: center;
        gap: 8px;
      }
    }

    &__title {
      color: var(--Topship-Colors-Black-Primary, #0f1414);
      font-family: General Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &__input {
      width: 15%;
      border-radius: 8px;
      padding: 8px 16px !important;
      border: 1px solid var(--Topship-Colors-Input-Outline, #e2e4e8);
      color: var(--Topship-Colors-Black-Primary, #0f1414);
      font-family: General Sans;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5NjIgMTAuOTc2NkwxNC4xMjQ2IDYuODUxNTZMMTUuMzAzIDguMDI5OUw5Ljk5OTYyIDEzLjMzMzJMNC42OTYyOSA4LjAyOTlMNS44NzQ2MiA2Ljg1MTU2TDkuOTk5NjIgMTAuOTc2NloiIGZpbGw9IiMwRjE0MTQiLz4KPC9zdmc+Cg==');
      background-repeat: no-repeat;
      background-position-x: calc(100% - 16px);
      background-position-y: 8px;

      &:focus,
      &:focus-visible {
        border: 1px solid #22428f !important;
      }

      @include respond(1200px) {
        width: 20%;
      }
      @include respond(900px) {
        width: 25%;
      }
      @include respond(600px) {
        width: 30%;
      }
      @include respond(500px) {
        width: 100%;
      }
    }

    &__wrap {
      padding: 40px 18.76em;
      display: flex;
      justify-content: center;
      gap: 4.4em;
      @include respond(1024px) {
        padding: 40px 16px;
      }
      @include respond(650px) {
        padding: 8px;
        flex-direction: column;
      }
    }

    &__divider {
      height: 97px;
      border-left: 1px solid #e2e4e8;
      transform: translateY(-10px);
      @include respond(650px) {
        height: 1px;
        width: 97px;
        margin: auto;
        transform: none;
        border-top: 1px solid #e2e4e8;
      }
    }

    &__item {
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
      }
      &__title {
        color: var(--Topship-Colors-Black-Secondary, #545859);
        font-family: General Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
      &__value {
        color: var(--Topship-Colors-Black-Primary, #0f1414);
        font-family: General Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }
      &__percentage {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--Topship-Colors-Black-Secondary, #545859);
        font-family: General Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        &__value {
          font-size: 14px;
          display: flex;
          align-items: center;
          &.negative {
            color: var(--Topship-Colors-Red, #f05d3d);
          }
          &.positive {
            color: var(--Topship-Colors-Red, #14cc3c);
          }
        }
      }
    }
  }

  .vib-page-container {
    padding: 40px 56px 156px 56px;

    @include respond(700px) {
      padding: 40px 24px 60px 24px;
    }
  }
  .dashboard-container {
    padding: 2% 4%;

    @include respond(600px) {
      padding: 100px 2%;
    }

    .link-container {
      cursor: pointer;
      margin-bottom: 50px;
      text-decoration: none;
      width: fit-content;
      border-radius: 50px;
      background-color: #f3f6f9;
      padding: 12px 15px;
      display: flex;
      align-items: center;

      font-size: 15px;

      @include respond(600px) {
        width: 100%;
        font-size: 14px;
      }
    }

    .new-badge {
      background-color: #e5edff;
      border-radius: 50px;
      color: #22428f;
      padding: 8px 15px;
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;

      @include respond(600px) {
        padding: 10px 12px;
        font-size: 12px;
      }
    }

    .link-text {
      white-space: nowrap;
      margin-left: 20px;
      color: #000000;

      @include respond(600px) {
        margin-left: 6px;
        line-height: 24px;
        white-space: normal;
      }
    }

    .lsp-business-alert {
      background-color: #ffdc23;
      border-radius: 8px;
      padding: 15px;
      text-align: center;
      margin-bottom: 50px;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      color: #0f1414;
      font-weight: 500;
      text-decoration: none;

      @include respond(700px) {
        margin-top: 20px;
      }
    }

    .lsp-alert-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(239, 165, 22, 0.1);
      padding: 20px;
      font-size: 1.5rem;
      line-height: 20px;
      border-radius: 6px;
      margin-bottom: 20px;
    }
    @include respond(780px) {
      .lsp-alert-container {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .all-resources-btn {
      color: #fff;
      background-color: #22428f;
      font-size: 11px;
      height: 44px;
      font-weight: 600;
      padding: 10px 25px;
      width: fit-content;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      white-space: nowrap;

      img {
        width: 6px;
        margin-left: 6px;
      }
      @include respond(780px) {
        margin-top: 20px;
      }
    }

    .banner-container {
      width: 100%;
      cursor: pointer;

      .banner-item {
        height: auto;
        width: 100%;
        text-decoration: none;
        padding: 0 20px;

        img {
          height: inherit;
          width: inherit;
          object-fit: contain;
        }

        @include respond(medium) {
          padding: 0 10px;
        }
      }
      .multi-banner {
        @include respond(medium) {
          padding: 0 30px;
        }
      }
    }

    @media screen and (min-width: 640px) {
      width: 100%;
      padding: 4% 5%;

      .show-ad-on-mobile {
        display: none;
        width: 100%;
      }

      .show-ad-table-and-above {
        display: block;
        width: 100%;
      }
    }

    .fund-wallet-cta {
      position: relative;
      display: flex;
      align-items: center;
      border: 1px solid #e2e4e8;
      border-radius: 8px;
      box-shadow: 0 3px 10px #eef1f8;
      // margin: 2% 0px;

      @include respond(480px) {
        margin-top: 20px;
      }

      .gift-image {
        position: absolute;
        top: -14%;
        left: 0;
        width: 32px;
        height: 32px;
        @include respond(480px) {
          top: -8%;
        }
      }

      .fund-wallet-cta-contents {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        padding: 8%;

        .wallet-balance-items {
          display: flex;
          flex-direction: column;
        }

        .wallet-balance-item {
          padding: 0 0 10%;
          padding: 0 10% 10% 0;
        }

        .wallet-balance-item-heading {
          color: #545859;
          font-weight: 400;
          padding: 0 0 5% 0;
        }

        .wallet-balance-figure {
          color: #000000;
          font-size: 32px;
          font-weight: 600;
          line-height: 43px;
          margin: 1% 0px 0px 0px;
        }

        .fund-wallet-btn-link {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #22428f;
          box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
          border-radius: 4px;
          padding: 22px 16.5px;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 140%;
          color: #ffffff;
          outline: 0;
          border: 0;
          cursor: pointer;
          a {
            color: #ffffff;
          }
        }

        @media screen and (min-width: 480px) {
          flex-direction: row;
          align-items: center;
          padding: 2%;

          .wallet-balance-items {
            flex-direction: row;
          }

          .wallet-balance-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0;
          }

          .wallet-balance-item:first-child {
            padding: 0 10% 0 0;
          }

          .wallet-balance-item-heading {
            padding: 0;
          }

          .fund-wallet-btn-link {
            padding: 11.5px 16.5px;
          }
        }
      }
    }

    .vib-status-cta {
      position: relative;
      display: flex;
      align-items: center;
      border: 1px solid #e2e4e8;
      border-radius: 8px;
      box-shadow: 0 3px 10px #eef1f8;
      padding: 2%;
    }

    .dashboard-cta-items {
      display: block;
      margin: 2em 0 8em;

      @media screen and (min-width: 640px) {
        flex-wrap: wrap;
        padding: 2% 0;
      }

      @media screen and (min-width: 769px) {
        display: flex;
        justify-content: space-between;
        grid-gap: unset;
        margin: 2em 0 4em;
        flex-wrap: wrap;
      }

      @media screen and (min-width: 1580px) {
        display: flex;
        justify-content: space-between;
        grid-gap: unset;
        margin: 2em 0 4em;
      }
    }
    .dashboard-cta-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      margin-top: calc(2% + 2em);
      @include respond(1350px) {
        flex-direction: column;
      }
      & > * {
        width: 100%;
      }

      #shipment-count,
      #wallet-balance {
        width: 400px;
        padding: 20px;
      }
    }

    .vib-shipment-container {
      height: 120px;
      margin-top: 0;
      width: 100%;
      display: flex;
      justify-content: flex-start !important;
      flex-direction: row !important;

      .vib-status {
        height: inherit;
        width: 25%;
        margin-right: 1.4%;
      }

      .shipment-count {
        height: inherit;
        width: 50%;
      }

      @include respond(1280px) {
        .vib-status {
          width: 30%;
        }
        .shipment-count {
          width: 70%;
        }
      }

      @media screen and (max-width: 1049px) {
        flex-direction: column !important;
        align-items: flex-start;
        height: auto;

        .vib-status {
          width: 40%;
          margin-bottom: 10px;
        }
        .shipment-count {
          width: 100%;
        }
      }

      @media screen and (max-width: 590px) {
        .vib-status {
          width: 100%;
        }
      }
    }

    .monthly-shipments {
      position: relative;

      .gift-image {
        position: absolute;
        top: -14%;
        left: 0;
        width: 32px;
        height: 32px;
      }
      .MuiInput-root {
        margin-top: 32px !important;
      }
      .MuiSelect-select {
        font-size: 1.2em !important;
        color: black !important;
      }
      .MuiFormControl-root {
        width: 40%;
        label {
          font-size: 1.5rem !important;
          color: #545859 !important;
          font-family: unset !important;
        }
      }
      @include respond(480px) {
        .fund-wallet-cta-contents {
          padding: 16px;
        }
        .wallet-balance-item {
          padding-bottom: 0 !important;
        }
        .MuiFormControl-root {
          width: 100%;
        }
      }
    }
    .dashboard-cta-item {
      width: 100%;
      height: 100px;
      height: 100%;
      min-height: 100%;
      border: 1px solid #eef1f8;
      border-radius: 8px;
      box-shadow: 0 3px 10px #eef1f8;
      cursor: pointer;
      margin-bottom: 20px;

      @media screen and (min-width: 375px) {
        width: 100%;
        min-width: 170px;
      }

      @media screen and (min-width: 425px) {
        width: 100%;
        min-width: 190px;
      }

      @media screen and (min-width: 768px) {
        width: 100%;
        min-width: 190px;
      }
      @media screen and (min-width: 769px) {
        width: 22%;
        min-width: 24%;
      }
    }

    a:-webkit-any-link {
      text-decoration: none;
      cursor: pointer;
    }

    .cta-item-contents {
      display: flex;
      flex-flow: column;
      padding: 15px 18px;
    }

    .cta-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cta-icon-image {
        width: 40px;
        height: 40px;
      }
    }

    .cta-texts {
      margin-top: 1rem;
      font-weight: 500;

      .cta-item-heading {
        margin-top: 0.5rem;
        color: #545859;
        font-size: 1.5rem;

        @media screen and (min-width: 769px) and (max-width: 800px) {
          font-size: 1.4rem;
        }
      }

      .cta-item-text {
        display: none;
        color: #000;
        font-size: 14px;
        margin: 0;

        @media screen and (min-width: 1024px) {
          display: block;
          font-size: 1.2em;
        }
      }
    }

    .recent-shipments {
      margin: 3rem 0;
      .charge-btn {
        font-weight: 600;
        color: #ffffff;
        padding: 12px 24px;
        border-radius: 8px;
        font-size: 14px;
      }
      .pending-btn {
        background-color: #22428f;
        border: 1px solid #22428f;
      }
      .success-btn {
        background-color: #43d882;
        border: 1px solid #43d882;
      }
    }

    .recent-shipments-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-weight: 600;
      }

      p {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #22428f;

        span {
          margin-right: 5px;
        }
      }
    }
  }
}

.complete__order-container {
  display: flex;
  justify-content: center;
  background: rgba(253, 245, 231, 1);
  padding: 0.8rem;
  border-radius: 0.8rem;
  margin-bottom: 2.4rem;
  cursor: pointer;
}

.complete__order-content {
  display: flex;
  gap: 0.4rem;
  align-items: center;
}

.complete__order-tracking--text {
  margin-bottom: 0rem !important;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: rgba(15, 20, 20, 1);
}

.roundup__prompt-and-service__closure-banner {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-bottom: 4.5rem;
  flex-direction: column;
}

.roundup__prompt-CTA--container {
  background-color: #22428f;
  width: 100%;
  display: flex;
  padding: 1.6rem 1.6rem 3.3rem 1.6rem;
  border-radius: 0.8rem;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.2rem;
}

.roundup__prompt-texts {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}

.roundup__promp-main--text {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9rem;
  text-align: left;
  margin-bottom: 0 !important;
  color: #e5edff;
}

.roundup__promp-sub--text {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.7rem;
  text-align: left;
  margin-bottom: 0 !important;
  color: #e5edff;
}

.year__rounup-CTA {
  background-color: #ffd708;
  padding: 1.2rem 2.4rem;
  height: 3.7rem;
  border: none;
  border-radius: 0.8rem;
  min-width: 12.4rem;
  max-width: 12.4rem;
  color: #22428f;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 13.2px;
  z-index: 1;
}

.roundup__CTA-text {
  width: 7.6rem;
  cursor: pointer;
}

.roundup__bg-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.plugin__prompt {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1.2rem;
  background-color: #e5edff;
  border-radius: 0.8rem;
  gap: 0.8rem;
  align-items: center;
  margin-bottom: 1.2rem;
  cursor: pointer;
}

.plugin__prompt__icons {
  display: flex;
  gap: 0.8rem;
  align-items: center;
}

.plugin__prompt__text {
  color: #2b3990;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: 0%;
  margin-bottom: 0 !important;
}

@media screen and (min-width: 48rem) {
  .roundup__prompt-and-service__closure-banner {
    flex-direction: row;
  }
}
@media screen and (min-width: 70rem) {
  .roundup__prompt-CTA--container {
    flex-direction: row;
    gap: 1.2rem;
  }
}
