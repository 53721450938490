.get-quotes-page {
  .quotes-container {
    margin: auto;
    margin-top: 10rem;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 50rem;
    width: 50rem;
    animation: fadeIn 1s;
    margin-top: 3rem;
    padding-bottom: 3rem;
    align-items: center;
    display: flex;
    height: 100vh;
  }
}
