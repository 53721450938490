.intro-text {
  padding-top: 3em;
  text-align: left;
  margin-bottom: 5em;

  & p {
    font-size: 1.35em;
    padding: 0.5em;
  }

  @include respond(mobile) {
    width: 85%;
    padding-top: 3rem;
    & p {
      font-size: 1.35em;
      padding: 0.5em;
    }
  }

  &__heading {
    color: #0f1414;
    font-weight: 800;
    font-size: 2.2rem;
    padding-top: 3em;
    @include respond(mini-mobile) {
      font-size: 1.7rem;
      padding-top: 2rem;
    }
  }

  &__sub-heading {
    color: #545859;
    font-size: 1.5rem;
    font-weight: 500;
    @include respond(mini-mobile) {
      font-size: 1.8rem;
    }
  }
}
