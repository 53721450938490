.terms-and-conditions {
  display: flex;
  flex-direction: column;
  @include dashboardWrapper;
  margin-top: 3rem;
  width: 60rem;

  &__logo {
    align-self: center;
    width: 10rem;
  }
  &__caption {
    align-self: center;
    margin-top: 2rem;
    text-decoration: underline;
    font-size: 1.7rem;
    font-weight: 600;
  }

  &__heading {
    font-size: 1.5rem;
    font-weight: 600;

    & span {
      margin-left: 1.5rem;
    }
  }

  & p,
  & ul {
    font-size: 1.3rem;
    line-height: 2rem;
    margin-top: 1rem;
    @include respond(mini-mobile) {
      font-size: 1.4rem;
    }
  }

  & ul {
    & li {
      margin-left: 2.5rem;
      line-height: 2rem;
    }
  }

  &__footer {
    margin-top: 2rem;
    margin-bottom: 4rem;
    font-size: 1.25rem;
    line-height: 1.7rem;
  }
}
