.maintenance {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  &__main {
    margin-top: 20vh;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__text {
      font-size: 1.7rem;
      width: 50rem;
      margin-top: 2rem;
      color: var(--color-gray);

      @include respond(mini-mobile) {
        width: 90%;
      }
    }

    &__sub-text {
      color: var(--color-gray);
      font-size: 1.4rem;
      margin-top: 3rem;

      & span {
        color: blue;
      }
    }

    @include respond(mini-mobile) {
      & > img {
        width: 90%;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;

    & svg {
      fill: #202223;
    }

    &__text {
      font-size: 1.3rem;
    }

    &__logo-white {
      display: none;
    }

    @include respond(mini-mobile) {
      flex-direction: column;
      align-items: flex-start;
      background-color: var(--color-blue-dark);

      &__logo {
        display: none;
      }

      &__logo-white {
        display: block;
        margin-bottom: 1rem;
      }
      & svg {
        fill: white;
      }
      &__text {
        color: white;
        margin-top: 1rem;

        & a {
          margin: 0;
          margin-right: 1rem;
        }
      }
    }
  }
}
