.form-container {
  width: 100%;

  form {
    width: 100%;
    padding: 10px 16px;
  }

  .form-item {
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .email {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }

  .form-item-label {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.25rem;
    margin-bottom: 1.5rem;
  }

  #email::placeholder {
    font-size: 1.5rem;
  }

  #email label {
    font-size: 1.5rem;
  }

  .remember-password {
    padding: 1.5rem 0;
    font-size: 1.5rem;
    text-align: center;
  }

  #email-helper-text,
  .MuiFormHelperText-root {
    margin: 3px 0 0;
    font-size: 1.2rem;
  }
  #email-helper-text {
    color: red;
  }
}
