.config {
  width: 100%;

  &__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 35px 56px;
    gap: 40px;

    @include respond(1200px) {
      padding: 35px 30px;
    }
  }

  &__heading {
    flex: 1;
    .new-referral__content {
      gap: unset;
      flex-direction: row;
      justify-content: space-between;
      @include respond(1200px) {
        gap: 40px;
        flex-direction: column;
      }
    }
    &__wrap {
      display: flex;
      flex-direction: column;
      gap: 24px;
      @include respond(1200px) {
        order: 2;
      }
    }
  }

  &__status {
    background: #ffffff;
    border: 1px solid #eef1f8;
    border-radius: 8px;
    flex: 0.625;
    @include respond(1200px) {
      order: 1;
    }

    &__icon {
      width: 40px;

      img {
        width: inherit;
      }
    }

    &__wrap {
      gap: 8px;
      padding: 16px 19.2% 16px 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__heading p {
      font-family: 'General Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #545859;
    }

    &__text h4 {
      font-family: 'General Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: #0f1414;
    }
    &__action button {
      gap: 8px !important;
      padding: 10px !important;
      border-radius: 8px !important;
      background: #e6edff !important;
      font-family: 'General Sans' !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 12px !important;
      line-height: 110% !important;
      color: #22428f !important;
      text-transform: unset !important;
      cursor: pointer;
    }
    :disabled {
      opacity: 0.7;
    }
  }

  .MuiTableRow-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
  .MuiTableCell-root {
    border-bottom: none;
  }
  .MuiInputBase-input {
    @include respond(1200px) {
      height: 0.74rem;
      font-size: 1.4rem !important;
    }
  }
  .submit {
    padding: 0.5em 1.4em;
    min-height: unset;
    margin: 0 !important;

    @include respond(900px) {
      padding: 0.5em 0.4em;
      font-size: 1.4rem;
    }
  }
  .is-cargo {
    width: 60%;
    margin-left: 15%;
  }
  .is-ups {
    width: 40%;
    margin-left: 25%;
  }
  &__mobile {
    display: none;
    @include respond(769px) {
      display: block;
    }
    &__wrap {
      padding: 32px 16px 80px 16px;
    }
    &__settings {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
    &__setting {
      &__stack {
        margin-top: -20px !important;
        gap: 5px;
      }
    }
    .is-cargo {
      width: 50%;
      margin-left: 25%;
    }
    .is-ups {
      width: 35%;
      margin-left: 30%;
      margin-bottom: 4%;
    }
  }

  .table-container {
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    @include respond(769px) {
      display: none;
    }
    h3 {
      font-size: 14px;
      font-weight: 500;

      @include respond(1200px) {
        font-size: 12px;
      }
    }

    .notification-header {
      width: 29vw;
      @include respond(1200px) {
        width: 20vw;
      }
      @include respond(900px) {
        width: 17vw;
      }
    }

    .action-header {
      width: 8vw;
      @include respond(1200px) {
        width: 10vw;
      }
      // @include respond(900px) {
      //   width: 17vw;
      // }
    }
    th {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #545859;
      text-transform: uppercase;
      word-wrap: none;
      @include respond(1200px) {
        font-size: 11px;
      }
    }
    td {
      padding: 0;
    }
    tr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 50px;

      @include respond(1200px) {
        padding: 10px 20px;
      }
      &.odd {
        background: #f8fbff;
      }
    }
  }
}
