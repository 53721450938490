.sea-freight {
  padding-bottom: 50px;
  width: 100%;

  &__header {
    border-bottom: 1px solid #eef1f8;
    width: 100%;
    height: 100px;

    @include respond(789px) {
      height: auto;
      padding: 30px 20px;
    }

    .header-wrap {
      width: 70%;
      margin: 0 auto;
      display: flex;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      @include respond(789px) {
        width: 100%;
        padding: 10px;
        height: auto;
        flex-direction: column;
      }
    }

    .title-close-wrap {
      .close-btn {
        display: none;
      }

      @media (max-width: 789px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 30px;

        .close-btn {
          display: block;
        }
      }
    }

    .web-close-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 789px) {
        display: none;
      }
    }

    .close-btn {
      transform: scale(2);
      padding: 4px;
      color: #22428f !important;

      @media (max-width: 789px) {
        transform: scale(1.4);
      }
    }

    h2 {
      font-weight: 700;
    }

    .cta-wrap {
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-left: 1px solid #eef1f8;
      border-right: 1px solid #eef1f8;
      padding: 0 40px;
      height: 100%;

      @media (max-width: 789px) {
        width: 100%;
        border-left: none;
        border-right: none;
        padding: 0;
      }

      @media (max-width: 358px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  &__body {
    border: 1px solid #e2e4e8;
    border-radius: 12px;
    padding: 40px 20px;
    width: 550px;
    margin: 50px auto;

    @include respond(789px) {
      width: 80%;
    }
    @include respond(500px) {
      width: 90%;
    }
  }

  .heading {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 47px;
    color: #22428f;
  }

  .continue-btn {
    background-color: #22428f;
    border: 1px solid #22428f;
    width: 100%;
    height: 48px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    border-radius: 10px;
    font-weight: 700;
    margin-top: 30px;
  }

  .go-back-btn {
    background-color: #fff;
    border: none;
    width: 100%;
    height: 48px;
    text-align: center;
    color: #22428f;
    font-size: 14px;
    border-radius: 10px;
    font-weight: 700;
    margin-top: 30px;
    cursor: pointer;
  }

  .guides-tip-container {
    border: 1px solid #e2e4e8;
    border-radius: 8px;
    display: flex;
    height: 40px;
    align-items: center;
    padding: 10px 15px;
  }
}
