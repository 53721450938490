.payment-method {
  &__bank-transfer {
    border-radius: 8px;
    border: 1px solid #e2e4e8;
    background: #fff;
    &__wrap {
      display: flex;
      flex-direction: column;
      gap: 17px;
      padding: 24px;
    }
    &__item {
      &__wrap {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
      }
      &__content {
        display: flex;
        flex-direction: column;
      }
      &__name {
        color: #545859;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-transform: capitalize !important;
      }
      &__value {
        color: #0f1414;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize !important;
      }
    }
    &__notice {
      display: flex;
      align-items: center;
      gap: 0.5em;
      &__text {
        color: var(--topship-brand-blue, #22428f);
        font-family: General Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
    }
  }
}
