$tablet_maximum: 768px;
$mobile_minimum: 320px;
.new-wallet {
  width: 100%;
  animation: fadeIn 0.5s;
  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding-top: 42px;
    @include respond(769px) {
      padding: 100px 16px 0 16px;
    }
  }
  &__balance {
    width: 396px;
    background: #ffffff;
    border: 1px solid #eef1f8;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    margin-left: 53px;
    @include respond(mini-mobile) {
      width: 100%;
      margin: 0 16px 0 0;
      align-self: center;
    }
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 32px 24px;
      @include respond(mini-mobile) {
        padding: 24px 20px;
      }
      @include respond($mobile_minimum) {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      &__header * {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #545859;
      }
      &__value * {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #0f1414;
      }
    }
    &__action * {
      background: #22428f;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      border-radius: 4px;
      padding: 11.5px 16.5px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: #ffffff;
      outline: 0;
      border: 0;
      cursor: pointer;
    }
  }
  &__transactions {
    .charge-btn {
      font-weight: 600;
      color: #ffffff;
      padding: 12px 24px;
      border-radius: 8px;
      font-size: 14px;
    }
    .pending-btn {
      background-color: #22428f;
      border: 1px solid #22428f;
      &.pending-charges {
        padding: 8px 16px;
      }
    }
    .success-btn {
      background-color: #43d882;
      border: 1px solid #43d882;
    }
    &__container {
      display: flex;
      flex-direction: column;
    }
    &__header {
      padding-left: 55px;
      @include respond(mini-mobile) {
        padding-left: 0;
      }
      * {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #0f1414;
      }
    }
    &__filters {
      margin-top: 32px;
      display: flex;
      align-items: center;
      gap: 40px;
      border-bottom: 1px solid #eef1f8;
      width: 100%;
      padding: 0 0 18px 51px;
      @include respond(mini-mobile) {
        padding-left: 0;
        gap: 20px;
      }
      @include respond($mobile_minimum) {
        gap: 3px;
      }
    }
    &__filter {
      cursor: pointer;
      &__content {
        display: flex;
        align-items: center;
        gap: 4px;
        &__name * {
          display: block;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #545859;
        }
        &__value * {
          background: rgba(34, 66, 143, 0.1);
          border-radius: 20px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: #22428f;
          padding: 1px 6px;
        }
      }
      &.active {
        position: relative;
        .new-wallet__transactions__filter__content {
          &__name * {
            color: #22428f;
          }
        }
        .new-wallet__transactions__filter__wrap::after {
          content: '';
          width: 100%;
          background: #22428f;
          border-radius: 2px 4px 0px 0px;
          height: 2px;
          position: absolute;
          bottom: -19px;
        }
      }
    }
    &__list {
      margin-top: 24px;
      border-top: 1px solid #eef1f8;
      border-bottom: 1px solid #eef1f8;
      @include respond($tablet_maximum) {
        display: none;
      }
      table {
        width: 100%;
      }
      th {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #545859;
        text-transform: uppercase;
        width: 12.5vw;
      }
      td {
        padding: 0;
        width: 12.5vw;
      }
      tr {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        padding: 16px 50px;
        &.odd {
          background: #f8fbff;
        }
      }
      &__amount {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #0f1414;
      }
      &__type {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #545859;
        text-transform: capitalize;
      }
      &__date {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #545859;
      }
      &__pending {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #545859;
      }
      &__status {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #545859;
        text-transform: capitalize;
        position: relative;
        &::before {
          content: '';
          width: 8px;
          height: 8px;
          transform: translate(-200%, 15px);
          border-radius: 50%;
        }
        &.Success::before {
          display: block;
          background: #10c54e;
        }
        &.Failed::before {
          display: block;
          background: #dc3545;
        }
        &.Pending::before {
          display: block;
          background: #ffc107;
        }
        &.Reversed::before {
          display: block;
          background: #17a2b8;
        }
      }
    }
    &__list__mobile {
      margin: 24px 16px 0 16px;
      @include respond(mini-mobile) {
        margin-left: 0;
      }
      @media (min-width: 769px) {
        display: none;
      }
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
      &__row {
        width: 100%;
        background: #ffffff;
        border: 1px solid #e2e4e8;
        box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        &__wrap {
          padding: 20px 16px;
        }
        &__body {
          padding-bottom: 19px;
          border-bottom: 1px solid #eeeeee;
          &__wrap {
            display: flex;
            flex-direction: column;
            gap: 15px;
          }
        }
        &__pair {
          display: flex;
          justify-content: space-between;
        }
        &__key * {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          color: #545859;
        }
        &__pending-value * {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: #0f1414;
          position: relative;
          &::before {
            content: '';
            width: 8px;
            height: 8px;
            right: 0;
            transform: translate(-800%, 6px);
            border-radius: 50%;
            position: absolute;
          }
          &.Success::before {
            display: block;
            background: #10c54e;
          }
          &.Failed::before {
            display: block;
            background: #dc3545;
          }
          &.Pending::before {
            display: block;
            background: #ffc107;
          }
          &.Reversed::before {
            display: block;
            background: #17a2b8;
          }
        }
        &__value * {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #0f1414;
          &::before {
            content: '';
            width: 8px;
            height: 8px;
            transform: translate(-200%, 15px);
            border-radius: 50%;
          }
          &.Success::before {
            display: block;
            background: #10c54e;
          }
          &.Failed::before {
            display: block;
            background: #dc3545;
          }
          &.Pending::before {
            display: block;
            background: #ffc107;
          }
          &.Reversed::before {
            display: block;
            background: #17a2b8;
          }
        }
        &__footer {
          padding-top: 16.5px;
        }
        &__time {
          display: flex;
          align-items: center;
          gap: 10.5px;
          span {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #545859;
          }
          svg {
            transform: scale(1.5);
            color: #22428f;
          }
        }
      }
    }
  }
  &__fund {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: scroll;
    outline: 0;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    animation: fadeOut 0.5s;
    &.show {
      animation: fadeIn 0.5s;
      display: block;
    }
    &__wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    &__header {
      &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 29px 17.4%;
        @include respond(mini-mobile) {
          padding: 20px 24px;
        }
      }
      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #0f1414;
      }
      button {
        transform: scale(2);
        color: #22428f !important;
      }
      border-bottom: 1px solid #eef1f8;
      @include respond(mini-mobile) {
        border-bottom: unset;
      }
    }
    &__body {
      height: 100%;
      &__wrap {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: unset;
        padding: 0 36.325% 0 17.4%;
        gap: 81px;
        @include respond(1200px) {
          padding: 0 18.1625% 0 17.4%;
        }
        @include respond(mini-mobile) {
          padding: 0 24px;
        }
      }
      &__label {
        padding: 60px 7.7% 0 0;
        height: 100%;
        border-right: 1px solid #eef1f8;
        @include respond(smaller) {
          display: none;
        }
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;
          color: #22428f;
          background: #f8fbff;
          border-radius: 8px;
          padding: 10px;
        }
      }
      &__content {
        height: 100%;
        padding-top: 60px;
        flex: 2;
        @include respond(mini-mobile) {
          padding-top: 24px;
        }
      }
      &__form {
        &__item {
          display: flex;
          flex-direction: column;
          &__label {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #1d1d1d;
            margin-bottom: 20px;
          }
          &__input {
            transition: all 0.4s ease !important;
          }
          &__button {
            margin-top: 32px !important;
          }
        }
      }
    }
  }
}
