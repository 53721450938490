.vib {
  font-family: 'General sans';
  width: 100%;

  .MuiFormControl-root {
    @include respond(small) {
      width: 100% !important;
      margin-top: 10px !important;
    }
    .MuiSelect-select {
      margin-top: 16px !important;

      @include respond(small) {
        margin-top: 8px !important;
      }
    }
    label {
      font-size: 1.5rem !important;
      color: #545859 !important;
      font-family: unset !important;
    }
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 35px 56px;
    gap: 40px;

    @include respond(small) {
      padding: 35px 26px;
    }
  }

  &__header {
    h2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: #0f1414;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #545859;
    }
  }

  &__ad {
    .web-banner {
      display: block;
      object-fit: contain;

      @include respond(700px) {
        display: none;
        object-fit: contain;
      }
    }

    .mobile-banner {
      display: none;

      @include respond(700px) {
        display: block;
      }
    }
  }

  &__status {
    &__items {
      display: flex;
      justify-content: flex-start;
      margin-top: 15px;

      @include respond(small) {
        display: block;
      }
    }

    &__type {
      border: 1px solid #e2e4e8;
      border-radius: 8px;
      box-shadow: 0 3px 10px #eef1f8;
      padding: 20px 60px 20px 20px;
      margin-right: 30px !important;
      width: fit-content;

      &__label {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #545859;
      }

      &__value {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #0f1414;
      }
    }

    &__stats {
      border: 1px solid #e2e4e8;
      border-radius: 8px;
      box-shadow: 0 3px 10px #eef1f8;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include respond(small) {
        width: 80%;
        margin-top: 20px;
        flex-direction: column;
      }
      @include respond(mobile) {
        width: 100%;
      }

      &__input {
        @include respond(small) {
          width: 100%;
        }
      }
      &__number {
        margin-right: 11px;
      }

      &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 40px;

        @include respond(small) {
          flex-direction: column;
          justify-items: flex-start;
          align-items: flex-start;
          width: 100%;
          margin-right: 0;
        }

        &__label {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #545859;
        }

        &__value {
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
          color: #0f1414;
        }
      }
    }
  }

  &__benefits {
    margin-top: 40px;
    &__items {
      display: flex;
      justify-content: flex-start;
      margin-top: 15px;

      @include respond(1125px) {
        display: block;
      }
    }

    &__learn-more {
      border: 1px solid #e2e4e8;
      border-radius: 8px;
      box-shadow: 0 3px 10px #eef1f8;
      padding: 20px 40px 10px 20px;

      &:first-child {
        margin-right: 30px;

        @include respond(1125px) {
          margin-right: 0;
        }
      }

      @include respond(small) {
        padding: 20px 20px 20px 20px;
      }

      @include respond(1125px) {
        margin-bottom: 20px;
      }

      &__label {
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #545859;
          display: block;

          @include respond(small) {
            font-size: 12px;
          }
        }
      }

      &__value {
        text-decoration: none;
        h2 {
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
          color: #22428f;
        }
      }
    }

    &__stats {
      border: 1px solid #e2e4e8;
      border-radius: 8px;
      box-shadow: 0 3px 10px #eef1f8;
      padding: 20px;

      &__wrap {
        display: flex;
        align-items: center;
      }
      &__number {
        background-color: #22428f;
        border-radius: 100%;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        font-size: 8px;
        padding: 6px;
        margin-right: 11px;
      }

      &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 20px;

        &__label {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #545859;
        }

        &__value {
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
          color: #0f1414;
        }
      }
    }
  }

  &__tier-benefits {
    width: 100%;
    margin-top: 40px;
    &__items {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      @include respond(1060px) {
        display: block;
      }
    }

    &__content {
      border: 1px solid #e2e4e8;
      border-radius: 8px;
      box-shadow: 0 3px 10px #eef1f8;
      padding: 30px 30px 50px 30px;
      margin-right: 5%;
      height: fit-content;

      @include respond(1198px) {
        margin-right: 3%;
        padding: 15px 15px 30px 15px;
      }

      @include respond(1060px) {
        margin-right: 0;
        padding: 30px 30px 50px 30px;
        margin-bottom: 20px;
      }

      &:nth-child(3) {
        margin-right: 0;
      }

      &__label {
        h2 {
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          color: #0f1414;
        }
      }

      &__value {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #545859;
      }

      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        img {
          margin-right: 10px;
        }
        span {
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          color: #545859;
        }
      }
    }

    .blue-bg {
      position: relative;
      background-color: #22428f;
      h2 {
        color: #fff !important;
      }

      p {
        color: rgba(255, 255, 255, 0.7) !important;
      }
      span {
        color: rgba(255, 255, 255, 0.7) !important;
      }
      .absolute-bg {
        position: absolute;
        z-index: 0;
        right: -10%;
        top: 0;
        width: auto;
        object-fit: contain;
      }
    }
  }
}
