.past-shipment-detail-modal {
  width: 100%;
  height: 100vh;
  z-index: 3;
  position: fixed;

  .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    padding: 0;
  }

  .css-i4bv87-MuiSvgIcon-root {
    font-size: 3rem;
    fill: #22428f;
  }

  .modal-half {
    width: 70%;
  }

  .button-group {
    display: flex;
    justify-content: space-between;

    @include respond(500px) {
      margin: 30px 0;
      flex-direction: column;
      gap: 12px;
    }
  }
  .past-shipment-detail-modal-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: fixed;
    height: 100%;
  }

  .get-help-content-wrap {
    margin-top: 30px;

    a {
      color: #0f1414;
      text-decoration: none;
    }
  }

  .get-help-content-item {
    border: 1px solid #eef1f8;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include respond(700px) {
      padding: 20px 10px;
    }
  }

  .past-shipment-modal-content {
    width: 100%;
    padding: 5%;
    background-color: #ffffff;
    overflow: scroll;
    @media screen and (min-width: 480px) {
      width: 480px;
      padding: 2%;
    }
  }

  .get-help-modal-content {
    .close-button {
      display: flex;
      justify-content: flex-end;
    }

    width: 100%;
    padding: 5%;
    background-color: #ffffff;
    overflow: scroll;
    @media screen and (min-width: 480px) {
      width: 580px;
      padding: 2%;
    }
  }

  .past-shipment-detail-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .get-help-modal-header {
    h2 {
      color: #0f1414;
      font-size: 20px;
      font-weight: 800;
    }
    p {
      font-size: 16px;
      font-weight: 500;

      @include respond(789px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .past-shipment-detail-delivery-item {
    border: 1px solid #e2e4e8;
    border-radius: 8px;
    margin: 5% 0;
    padding: 5%;
    @media screen and (max-height: 800px) {
      margin: 2.5% 0;
      padding: 2.5% 5%;
    }
  }

  .past-shipment-location-label {
    font-size: 12px;
    font-weight: 500;
    color: #545859;
    text-transform: uppercase;
    margin-left: -1%;
  }

  .delivery-status-item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5% 0;
    border-bottom: 1px solid #eeeeee;
  }

  .delivery-status-item-content:last-child {
    border-bottom: none;
  }

  .content-item {
    display: flex;
    flex-direction: column;
  }

  .content-label {
    font-size: 12px;
    font-weight: 500;
    color: #545859;
    text-transform: uppercase;
    margin-bottom: 4%;
  }

  .content-value {
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
  }

  .past-shipment-node-origin {
    background-image: linear-gradient(
      var(--color-blue-dark) 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: left;
    background-size: 1px 3px;
    background-repeat: repeat-y;
  }

  .past-shipment-address {
    padding: 1rem;
    margin-left: 0.2rem;

    h4 {
      font-weight: normal;
      color: #545859;
    }

    p {
      color: #545859;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .past-shipment-detail-address {
    font-weight: 500;
    color: #0f1414 !important;
  }

  .track-shipment {
    margin-top: 20px;
    background-color: #22428f;
    color: #ffffff;
    font-weight: 600;
    height: 40px;
    padding: 0 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    @media screen and (max-height: 800px) {
      margin-top: -5px;
    }
  }

  .cancel-shipment {
    margin-top: 20px;
    background-color: red;
    color: #ffffff;
    font-weight: 600;
    height: 40px;
    padding: 0 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    @media screen and (max-height: 800px) {
      margin-top: -5px;
    }
  }

  .document-button {
    border: 1px solid #22428f;
    color: #22428f;
    background: transparent;
    border-radius: 5px;
    padding: 8px;
    font-size: 13px;
    white-space: nowrap;
  }

  .item-table {
    font-size: 15px;
  }
}

@media screen and (min-width: 480px) {
  .past-shipment-detail-modal {
    display: flex;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 3;
  }

  .past-shipment-detail-modal-container {
    width: 480px;
  }

  .other-half {
    width: 50%;
    height: 100%;
    background-color: #ffffff;
  }
}

@media screen and (max-width: 480px) {
  .document-button {
    font-size: 10px !important;
    padding: 5px !important;
  }

  .item-table {
    font-size: 13px !important;
  }
} ;
