.how__it-works--content {
  background-color: #ffffff;
  width: 100%;
  max-width: 65.3rem;
}

.how__it-works--heading-box {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border-bottom: 1px solid #e2e4e8;
}

.how__it-works--heading {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.7rem;
  text-align: left;
  margin-bottom: 0 !important;
}

.how__it-works--accordions-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 2rem;
}

.how__it-works--accordion-container {
  background-color: #f8fbff;
  padding: 1.5rem;
  border-radius: 0.8rem;
  // overflow: hidden;
  // transition: all 0.3s ease-in-out;
  // max-height: 500rem;
  cursor: pointer;
}

.accordion__closed {
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  // max-height: 30rem;
}

.closed__text {
  color: #0f1414 !important;
}

.how__it-works--accordion-title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.accordion__title-text {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.6rem;
  text-align: left;
  margin-bottom: 0 !important;
  color: #22428f;
}

.accordion__body-text {
  margin-bottom: 0 !important;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #545859;
  padding-top: 1.3rem;
}

.closed__text > p {
  margin-bottom: 0 !important;
}

.how__it-works--cta-box {
  border-top: 1px solid #e2e4e8;
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.how__it-works--cta {
  width: 100%;
  background-color: #22428f;
  border: none;
  border-radius: 0.8rem;
  color: #ffffff;
  padding: 1.4rem 0;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 19.6px;
  text-align: center;
}

.see__how-it--works--cta {
  color: #22428f;
  font-family: General Sans;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.89rem;
  padding: 0.95rem 1rem;
  border: 1px solid #9bb8ff1a;
  background-color: #9bb8ff1a;
  width: fit-content;
  border-radius: 1.6rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

@media screen and (min-width: 62.5rem) {
  .how__it-works--heading-box {
    padding: 3.5rem 2.4rem;
  }

  .how__it-works--heading {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.7rem;
  }

  .how__it-works--accordion-container {
    padding: 0rem;
  }

  .how__it-works--accordions-container {
    gap: 1.6rem;
    padding: 2.4rem;
  }
  .accordion__title-text {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2.1rem;
  }

  .how__it-works--accordion-title-box {
    padding: 2.4rem;
  }

  .accordion__body-text {
    padding: 0 2.4rem;
    padding-bottom: 2.4rem;
    line-height: 2.3rem;
  }

  .how__it-works--cta-box {
    padding: 2.4rem;
  }

  .see__how-it--works--cta {
    margin-bottom: 2.4rem;
  }
}
