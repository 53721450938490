.shop-n-ship {
  &__container {
    margin: auto;
    margin-top: 2rem;
    width: 40%;
    min-width: 46.5rem;
    padding: 6rem 0;
    animation: fadeIn 1s;

    h1 {
      padding-top: 2rem;
    }

    p {
      font-size: 1.25rem;
      line-height: 1.5;
    }
    &__intro-text {
      width: 90% !important;
      font-size: 2.5rem;
      padding-top: 2rem;

      &__heading {
        color: var(--color-blue-dark);
        font-weight: 800;
        font-size: calc(1.8rem + 1vw);
        padding-top: 3em;

        @include respond(mini-mobile) {
          font-size: 2.5rem;
          padding-top: 2rem;
        }
      }

      &__sub-heading {
        color: var(--color-sub-heading);
        font-size: 1.5rem;
        font-weight: 500;
        @include respond(mini-mobile) {
          font-size: 1.8rem;
        }
      }
    }

    & form {
      margin: 4rem 0;
      padding-bottom: 4rem;
      border-radius: 15px;
      // width: 35vw;
      // min-width: 450px;
      background-color: #fff;
      // position: fixed;
      // z-index: 100;

      @include respond(smaller) {
        width: 100%;
        border: none;
        height: 100vh;
        border-radius: unset;
      }

      @include respond(mobile) {
        width: 100%;
        border: none;
      }
    }

    &__shippingFrom {
      display: flex;
    }

    .radio-group {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .radio {
      padding-left: 35px;
      margin-bottom: 15px;
      display: inline-flex;
      align-items: center;
    }

    .radio input {
      margin: 0;
    }

    .radio span {
      font-size: 1.2rem;
      padding-left: 10px;
    }

    @include respond(mini-mobile) {
      width: 93%;
      min-width: 93%;

      &__form {
        padding: 5rem 0;
      }
    }
  }
  &__short-details {
    font-size: 1.2rem;
    padding-left: 35px;
  }
}
