.new-shipping-steps-layout {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  animation: fadeIn 0.5s;
  &__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__header {
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding: 29px 17.4%;
      @include respond(1000px) {
        padding: 29px 8.7%;
      }
      @include respond(mini-mobile) {
        padding: 20px 24px;
      }
    }
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: #0f1414;
    }
    button {
      transform: scale(2);
      padding: 4px;
      color: #22428f !important;
    }
    border-bottom: 1px solid #eef1f8;
    @include respond(mini-mobile) {
      border-bottom: unset;
    }
  }
  &__body {
    height: 100%;
    &__wrap {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: unset;
      padding: 0 19.09% 0 14.4%;
      gap: 81px;
      @media screen and (min-width: 72rem) {
        padding: 3rem 31rem 3rem 16rem;
      }
      // @include respond(1308px) {
      //   padding: 0 12.09% 0 14.4%;
      // }
      @include respond(1308px) {
        padding: 0 8rem;
        // padding: 0 11.045%;
      }
      @include respond(1000px) {
        flex-direction: column;
        gap: 0px;
      }
      @include respond(mini-mobile) {
        padding: 0 16px;
      }
    }
    &__label {
      display: flex;
      flex-direction: column;
      gap: 18px;
      padding: 60px 7.7% 0 0;
      height: 100%;
      border-right: 1px solid #eef1f8;
      @media screen and (min-width: 69rem) {
        padding: 6rem 2rem 0 0;
      }
      @include respond(1000px) {
        height: unset;
        flex-direction: row;
        border-right: none;
        justify-content: space-between;
        padding: 16px 8px;
        width: 100%;
        display: none;
      }
      span {
        font-style: normal;
        font-size: 16px;
        line-height: 160%;
        padding: 10px;
        font-weight: 400;
        color: #545859;
        cursor: pointer;
        &.completed {
          color: #0f1414;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 6px;
        }
        &.active {
          font-weight: 500;
          color: #22428f;
          background: #f8fbff;
          border-radius: 8px;
        }
        @include respond(1000px) {
          font-size: 0px;
          padding: 10px 0;
          .two-icons {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          svg {
            transform: scale(2);
          }
          .check {
            display: none;
          }
          &.completed {
            svg path {
              fill: #22428f;
            }
          }
        }
      }
    }
    &__progress {
      display: none;
      @include respond(1000px) {
        display: block;
        width: 100%;
        padding: 0;
        margin-bottom: 2rem;
        div {
          border-radius: 8px;
        }
      }
    }
    &__content {
      height: 100%;
      padding-top: 60px;
      flex: 2;
      @include respond(1000px) {
        width: 100%;
        padding-top: 0rem;
      }
      // @include respond(mini-mobile) {
      //   // padding-top: 24px;
      //   width: 100%;
      // }

      .mobile-header {
        display: block;
        font-weight: 600;
        font-size: 2.2rem;
        margin: 3rem 0;
        color: #22428f;
        @media screen and (min-width: 62.5rem) {
          display: none;
        }
      }
    }
  }

  .guides-tip-container {
    background-color: #fff;
    border: 1px solid #e2e4e8;
    border-radius: 8px;
    display: flex;
    // position: absolute;
    align-items: center;
    padding: 10px 15px;
    // top: 35px;
    @include respond(1192px) {
      left: 50%;
    }

    @include respond(600px) {
      left: 50px;
      top: 80px;
    }
    @include respond(500px) {
      left: 20px;
    }
  }
}
