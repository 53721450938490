.profile {
  width: 100%;
  &__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 35px 21.6vw 0 56px;
    gap: 40px;

    @include respond(1054px) {
      padding: 55px;
    }
    @include respond(769px) {
      padding: 100px 25px;
    }
  }
  &__header {
    &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include respond(small) {
        display: block;
      }
    }
  }
  &__avatar {
    &__wrap {
      display: flex;
      gap: 24px;
      align-items: center;

      @include respond(small) {
        display: block;
      }
      h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #0f1414;

        @include respond(small) {
          margin-top: 30px;
        }
      }

      .MuiSvgIcon-root {
        fill: #fff;
        height: 1.3em !important;
        width: 1.3em !important;
      }
    }
  }
  &__vib-status {
    background: #ffffff;
    border: 1px solid #e2e4e8;
    border-radius: 8px;
    box-shadow: 0 3px 10px #eef1f8;
    width: fit-content;
    @include respond(small) {
      margin-top: 40px;
    }
    &__wrap {
      display: flex;
      flex-direction: column;
      padding: 16px 48px 16px 16px;
      gap: 8px;
    }
    &__icon {
      width: 24px;
      height: 24px;
    }
    &__label {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #545859;
    }
    &__value {
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: #0f1414;
    }
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  &__input {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include respond(small) {
      display: block;
    }

    &__label {
      display: block;
      width: 40%;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #545859;

      @include respond(small) {
        // display: none;
        width: 100%;
      }
    }

    .account-label {
      @include respond(small) {
        display: block !important;
      }
    }
    &__control-label {
      gap: 8px;
      span.MuiTypography-root {
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 22px !important;
        color: #545859 !important;
      }
    }
    button {
      border-radius: 8px !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 22px !important;
      text-transform: none !important;
      display: block;

      @include respond(mobile) {
        width: 100% !important;
      }
    }
    &__button-light {
      padding: 13px 32px !important;
      background: #f8fbff !important;
      border: 1px solid #eef1f8 !important;
      color: #545859 !important;
    }

    &__button-blue {
      padding: 13px 46px !important;
      background: #22428f !important;
      border: 1px solid #22428f !important;
      color: #fff !important;
    }
  }
  #upload-button {
    display: flex;
    flex-direction: column;
    .filename {
      margin-top: 10px;
      font-size: 1.5rem;
    }
    label {
      padding: 9px 7px;
      border-radius: 20px;
      border: 2px solid #22428f;
      color: #22428f;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      width: fit-content;
      text-transform: capitalize;
    }
  }
}
