.contact-support {
  color: #fff;
  width: 100%;
  top: 0px;
  text-align: center;
  background-color: var(--color-blue-dark);
  display: flex;
  align-items: center;
  z-index: 3;

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
  }

  &__text {
    margin-right: 30px;
  }

  h5 {
    font-size: 1.5rem;
    margin: 0;
  }

  &__details {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      width: fit-content;
      display: flex;
      align-items: center;
      margin: 0;

      a {
        cursor: pointer;
      }
    }

    p:first-child {
      margin-right: 20px;
    }

    & span {
      font-size: 1.3rem;
      font-weight: 500;
      margin-left: 5px;
    }
  }

  &__contact-icon {
    background: #22428f;
    border-radius: 50%;
    path {
      stroke: #ffffff;
    }
  }

  &__whatsapp-icon {
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
    width: 20px;
  }

  @include respond(mobile) {
    &__container {
      display: block;
    }
  }
}
