.verification__modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 65.3%;
  z-index: 1050;
  display: none;
  overflow: scroll;
  outline: 0;
  height: 100vh;
  width: 34.7vw !important;
  background-color: #fff;
  animation: fadeOut 0.5s;

  &.show {
    animation: fadeIn 0.5s;
    display: block;
  }

  @include respond(1000px) {
    left: 60%;
    width: 40vw !important;
  }

  @include respond(800px) {
    left: 50%;
    width: 50vw !important;
  }

  @include respond(650px) {
    left: 0;
    width: 100vw !important;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    @include respond(smaller) {
      justify-content: center;
      align-items: center;
    }
  }

  &__header {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__steps {
    display: flex;
    gap: 8px;
  }
  &__step {
    width: 5.6vw;
    height: 4px;
    background: #dcebff;
    border-radius: 3px;
    cursor: pointer;
    &.complete {
      background: #22428f;
    }

    @include respond(smaller) {
      width: 100%;
    }
  }
  &__action {
    align-self: flex-end;
    color: #22428f !important;
  }
  &__heading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #0f1414;
  }
  &__completion-percent {
    .progress-background {
      fill: none;
      stroke: #ddd;
    }
    .progress-circle {
      fill: none;
      stroke: #e39600;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    .progress-text {
      font-size: 20px;
      color: #e39600;
      font-weight: 600;
    }
  }
}
