.in-app {
  &__prompt {
    animation: fadeIn 1s;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2000;
    // margin-top: 60px;
    padding-bottom: 50px;
    display: none;
    overflow: auto;
    // background-color: #fff;
    outline: 0;
    width: 100%;
    height: 100%;
    // max-width: 100%;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    // min-height: fit-content;
    // max-height: 80vh;

    &.isSaver {
      width: 40%;
      max-width: 100%;
      height: fit-content !important;
      background-color: #fff;
      margin-top: 50px;

      @include respond(1000px) {
        width: 70%;
      }

      @include respond(600px) {
        width: 100%;
      }
    }

    &.isItemBand {
      width: 40%;
      max-width: 100%;
      max-height: 800px;
      min-height: 400px;
      background-color: #fff;
      margin-top: 50px;

      @include respond(1000px) {
        width: 70%;
      }

      @include respond(600px) {
        width: 100%;
      }
    }

    .custom-box {
      width: 31%;
      height: 250px;
      padding: 10px;
      border: 1px solid #e2e4e8;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      &.active {
        box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12),
          0px 0px 0px 3px #d4e5ff;
        border: 2px solid #22428f;
      }
    }

    ::-webkit-scrollbar {
      display: none;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    // @include respond(1000px) {
    //   width: 80%;
    //   margin-top: 40px;
    // }
    // @include respond(700px) {
    //   width: 100%;
    //   border-radius: 0;
    //   padding-bottom: 100px;
    //   margin-top: 0;
    //   height: 600px;
    //   overflow: scroll;
    // }
    &.fade {
      opacity: 0;
      transition: opacity 0.15s linear;
    }
    &.fade.show {
      opacity: 1;
      display: block;
    }

    .title {
      font-weight: 700;
      font-size: 30px;
      line-height: 44px;
      letter-spacing: -0.02em;
      padding: 0;
      color: #000000;

      @include respond(1000px) {
        font-size: 26px;
        line-height: 38px;
      }
      @include respond(700px) {
        font-size: 24px;
        line-height: 34px;
        width: 100%;
      }
    }

    &__terms-condition {
      // width: 100%;
      background-color: #fff;
      overflow: scroll;
      padding: 40px 100px;
      margin: 5% auto;
      width: 80%;
      max-width: 800px;
      overflow-y: auto;
      max-height: 80vh;
      @include respond(1000px) {
        padding: 50px;
      }
      @include respond(500px) {
        padding: 50px 20px;
        width: 100%;
        height: 400px;
      }

      h2 {
        width: 100% !important;
        color: #22428f !important;
        text-align: center !important;
        display: flex;
        justify-content: center;
      }

      .china-conditions {
        border: 1px solid #e2e4e8;
        box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        margin: 30px 0;
        padding: 30px;

        .condition-item {
          padding-right: 0 !important;
          display: flex;
          align-items: flex-start;
        }
        @include respond(600px) {
          padding: 16px;

          .condition-item {
            flex-direction: column;
          }
        }

        .item-text {
          font-size: 20px !important;
          line-height: 32px;
          margin-left: 20px;
          color: #545859;

          @include respond(600px) {
            font-size: 16px !important;
            line-height: 26px;
            margin-left: 0;
            margin-top: 20px;
          }
        }

        .index {
          border-radius: 50%;
          border: 1px solid #e2e4e8;

          padding: 10px 20px;

          font-size: 28px;
          font-weight: 700;
          color: #22428f;
          margin-right: 5px;

          @include respond(600px) {
            font-size: 20px;
            margin-right: 0;
            padding: 5px 15px;
          }
        }
      }

      .conditions {
        height: fit-content;
        border: 1px solid #e2e4e8;
        box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        margin: 20px 0;
        display: block;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        align-items: flex-start;
        padding: 30px 30px 0;

        @include respond(789px) {
          display: block;
          padding: 30px 10px;
        }
      }

      .condition-item {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px;

        @include respond(600px) {
          margin-bottom: 20px;
        }
        text-align: left;
        padding-right: 20px;

        img {
          padding-right: 20px;
        }
      }

      .item-title {
        font-size: 20px;
        font-weight: 700;
        color: #0f1414;
        margin-bottom: 10px;
      }

      .item-text {
        font-size: 14px;
        line-height: 24px;
        color: #545859;
        font-weight: 500;
      }

      .cancellation {
        width: 100%;
        background-color: rgba(239, 165, 22, 0.1);
        display: flex;
        text-align: left;
        align-items: flex-start;
        padding: 30px;
        border-radius: 8px;

        img {
          padding-right: 10px;
        }

        p {
          font-size: 16px;
          line-height: 28px;
          color: #0f1414;
          margin: 0;
        }

        @include respond(600px) {
          padding: 20px 10px;

          p {
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }

    &__wrap {
      width: 100%;
      background-color: #fff;
      overflow: scroll;
      height: 100%;

      &.isSaver,
      &.isItemBand {
        height: fit-content;
      }
    }

    &__header {
      padding: 12px 24px;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e2e4e8;
      @include respond(mini-mobile) {
        margin-top: 20px;
      }
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;

        @include respond(mini-mobile) {
          font-size: 14px;
        }
      }

      p {
        font-size: 14px;
        line-height: 21px;
        color: #0f1414;
        @include respond(mini-mobile) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    &__body {
      height: fit-content;
      border-bottom: 1px solid #e2e4e8;
      padding: 20px 24px;
    }

    &__footer {
      &__wrap {
        padding: 20px;
        @include respond(mini-mobile) {
          padding: 16px 0;
        }
      }
      &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // gap: 8px;

        &.isSaver {
          @include respond(789px) {
            flex-direction: column !important;
            gap: 10px;
            button {
              width: 90%;
            }
          }
        }

        button {
          cursor: pointer;
          box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          border: 0;
          // padding: 9px 24px;
          height: 50px;
          width: 46%;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 140%;
          &:first-child {
            background: #ffffff;
            border: 1px solid #e2e4e8;
            color: #22428f;
          }
          &:last-child {
            background-color: #22428f;
            color: #ffffff;
            &:disabled {
              opacity: 0.4;
              background: #b7b7b7;
            }
          }
        }

        .accept {
          background: #f05d3d !important;
          border: 1px solid #f05d3d !important;
          color: #fff !important;
        }
      }
    }
    &__backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #000;
      display: none;
      &.fade {
        opacity: 0;
        transition: opacity 0.15s linear;
      }
      &.fade.show {
        display: block;
        opacity: 0.5;
      }
    }
  }
}
