.pending-shopnships {
  gap: 2em;
  margin: 0 2em;
  display: flex;
  flex-direction: column;

  @include respond(600px) {
    margin-top: 30px;
  }
}

.pending-shopnship {
  border-radius: 8px;
  background-color: #fffaf0;
  border: 1px solid #efa516;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;

    @include respond(900px) {
      gap: 30px;
    }

    @include respond(600px) {
      gap: 8px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__content {
    display: flex;
    align-items: baseline;
    gap: 12px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__header {
    font-size: 16px;
    color: #efa516;
    font-weight: 700;
    line-height: 1.75;
    font-family: General Sans;
  }

  &__description {
    font-size: 14px;
    color: black;
    font-weight: 400;
    line-height: 1.5;
    font-family: General Sans;
  }

  &__action button {
    padding: 0 1.4em;
    font-family: General Sans !important;
    @include respond(600px) {
      margin-left: 2em !important;
    }
  }
}
