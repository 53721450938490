.service__closure-banner {
  background: #fffcf4;
  border: 1px solid #efeadb;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 0.8rem;
}

.service__closure-text--container {
  padding: 1.6rem;
}

.service__closure-text {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.96rem;
  text-align: left;
  color: #9e7201;
}

.service__closure-icon {
}
