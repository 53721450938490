.shipment-bonus {
  @include dashboardWrapper;
  margin-top: 3rem;

  &__balance {
    background-color: var(--color-wallet-card);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8rem 0 2rem 0;

    &__amount {
      color: var(--color-blue-dark);
      font-weight: 700;
      font-size: 2.5rem;
    }

    &__instruction {
      color: var(--color-red);
      font-size: 1.3rem;
      max-width: 70%;
      text-align: center;
      margin-top: 2rem;
      line-height: 2rem;
    }
  }

  & input {
      margin-top: 3rem;
  }
}
