.sender-info {
  display: flex;
  flex-direction: column;
  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1d1d1d;
    margin-bottom: 20px;
  }
  &__select {
    justify-content: space-between !important;
    padding: 12px 16px !important;
    background: #ffffff !important;
    border: 1px solid #22428f !important;
    border-radius: 8px !important;
    &:focus {
      box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 3px #d4e5ff !important;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #0f1414;
      text-transform: none;
    }
    svg {
      transform: rotate(90deg) scale(1.5);
      path {
        fill: #545859;
      }
    }
  }
  &__divider {
    margin: 35px 0;
    display: flex;
    align-items: center;
    gap: 76.5px;
    span:not(:nth-child(2)) {
      display: block;
      width: 100%;
      height: 1px;
      background: #eef1f8;
    }
    span:nth-child(2) {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #22428f;
    }
  }
  &__checkbox {
    width: fit-content;
    margin: 20px 0 24px 0;
    &.order-summary {
      align-items: flex-start !important;
      span:nth-child(2) {
        font-weight: 600;
        font-size: 16px;
        @include respond(mini-mobile) {
          font-size: 14px;
        }
      }
    }
    span:first-child {
      transform: scale(1.5);
      svg path {
        border: 2px solid #b7b7b7;
        border-radius: 4px;
      }
    }
    span:nth-child(2) {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #545859;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    gap: 16px;
    &__action {
      cursor: pointer;
      width: 100%;
      background: #ffffff;
      border: 1px solid #e2e4e8;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 14px 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #22428f;
      text-align: center;
    }
  }
  &__choose-address {
    animation: fadeIn 1s;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1071;
    margin-top: 65px;
    margin-bottom: 5em;
    display: none;
    overflow: hidden;
    outline: 0;
    height: fit-content;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    @include respond(1200px) {
      width: 80%;
      margin-top: 4em;
    }
    @include respond(mini-mobile) {
      width: 100% !important;
      height: 100vh;
      margin-top: 0;
      border-radius: 0;
    }
    &.fade {
      opacity: 0;
      transition: opacity 0.15s linear;
    }
    &.fade.show {
      opacity: 1;
      display: block;
    }
    .empty-list {
      min-width: unset;
      width: 70%;
      margin: 1em 10%;
      align-self: center;
      .intro-text {
        min-width: unset;
        width: 100%;
        margin: 1em 0 !important;
        padding-top: 1.5em;
        &__heading {
          padding-top: 1em;
        }
      }
    }
    &__wrap {
      display: flex;
      flex-direction: column;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #0f1414;
      }
      button {
        transform: scale(1.5);
        svg path {
          fill: #22428f;
        }
      }
    }
    &__body {
      background: #f8fbff;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      &__wrap {
        display: flex;
        flex-direction: column;
        padding: 25.5px 16px;
        gap: 16px;
        @include respond(mini-mobile) {
          padding: 8px;
        }
      }
      &__text {
        text-align: center;
        margin: 0;
        font-size: 12px;
        font-weight: lighter;
      }
    }
    &__search {
      padding: 0 8px;
      .MuiInputBase-root.MuiInput-root {
        border: 0 !important;
        box-shadow: none !important;
        background: #f8fbff !important;
      }
    }
    &__list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 40vh;
      padding: 8px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background: #eef1f8;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        background: #22428f;
      }
      // @include respond(mini-mobile) {
      //   height: 400px;
      // }
    }
    &__address {
      background: #ffffff;
      border: 1px solid #e2e4e8;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      transition: all 0.3s ease;
      cursor: pointer;
      &.active {
        box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12),
          0px 0px 0px 3px #d4e5ff;
        border: 2px solid #22428f;
        .shipment-type__choose-address__address {
          &__name h3 {
            color: #22428f;
          }
        }
      }
      &__wrap {
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include respond(mini-mobile) {
          padding: 16px;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        gap: 7px;
      }
      &__name {
        display: flex;
        align-items: center;
        gap: 16px;
        h3 {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          text-transform: uppercase;
          color: #0f1414;
        }
        span {
          background: rgba(17, 17, 17, 0.1);
          border-radius: 16px;
          padding: 8px 10px;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #0f1414;
        }
      }
      &__address span {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #545859;
      }
      &__radio {
        svg path {
          fill: #22428f;
        }
      }
    }
    &__footer {
      &__wrap {
        padding: 20px 24px;
        @include respond(mini-mobile) {
          padding: 16px;
        }
      }
      &__actions {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 8px;
        button {
          cursor: pointer;
          border-radius: 8px;
          font-style: normal !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 19px !important;
          text-transform: none !important;
          padding: 9px 24px !important;
          &:disabled {
            opacity: 0.4;
          }
        }
        button:first-child {
          background: #eef1f8;
          gap: 6px;
          span {
            color: #22428f !important;
          }
          svg path {
            fill: #22428f;
          }
        }
        button:last-child {
          background-color: #22428f;
          color: #ffffff;
          &:disabled {
            opacity: 0.4;
            background: #b7b7b7;
          }
        }
      }
    }
    &__backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #000;
      display: none;
      &.fade {
        opacity: 0;
        transition: opacity 0.15s linear;
      }
      &.fade.show {
        display: block;
        opacity: 0.5;
      }
    }
  }
}
