.booking-successful {
  &__prompt {
    animation: fadeIn 1s;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1071;
    margin-top: 130px;
    display: none;
    overflow: scroll;
    outline: 0;
    width: 500px;
    height: fit-content;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    @include respond(mini-mobile) {
      width: 95%;
    }
    .new-shop-n-ship__prompt__body__options__list {
      flex-direction: row;
      gap: 30px;
      margin-left: 10px;
    }
    &.fade {
      opacity: 0;
      transition: opacity 0.15s linear;
    }
    &.fade.show {
      opacity: 1;
      display: block;
    }
    &__wrap {
      display: flex;
      flex-direction: column;
    }
    &__body {
      &__wrap {
        padding: 65px 52px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        background: #f8fbff;
        &.openFeedback {
          padding: 24px;
        }
        @include respond(mini-mobile) {
          padding: 40px 16px;
        }
      }
    }
    &__icon img {
      width: 100%;
    }
    &__header h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      color: #0f1414;
    }
    &__content span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #545859;
      display: block;
    }
    &__footer {
      background-color: #fff;
      &__wrap {
        padding: 24px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      &__ratings {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        h4 {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          color: #0f1414;
        }
      }
    }
    &__actions {
      display: flex;
      align-items: center;
      gap: 6px;
      button:disabled {
        opacity: 0.4;
      }
      button:first-child {
        width: 100%;
        background: #ffffff;
        border: 1px solid #e2e4e8;
        box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        padding: 9px 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: #22428f;
        cursor: pointer;
      }
      button:last-child {
        background: #22428f !important;
        box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05) !important;
        border-radius: 4px !important;
        padding: 9px 25px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 12px !important;
        line-height: 140% !important;
        color: #ffffff !important;
        text-transform: none !important;
        cursor: pointer;
      }
    }
    &__backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #000;
      display: none;
      &.fade {
        opacity: 0;
        transition: opacity 0.15s linear;
      }
      &.fade.show {
        display: block;
        opacity: 0.5;
      }
    }
  }
}
