.delivery-options {
  display: flex;
  flex-direction: column;
  gap: 22px;
  .empty-list {
    min-width: unset;
    width: 100%;
    margin: 1em 10%;
    align-self: center;
    .intro-text {
      min-width: unset;
      width: 100%;
      margin: 1em 0 !important;
      padding-top: 1.5em;
      &__heading {
        padding-top: 1em;
      }
    }
  }

  .arrow-icon {
    transition: transform 0.3s ease;
  }

  .upside-down {
    transform: rotate(180deg);
  }
  .toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    cursor: pointer;
  }

  .border-shadow {
    border-bottom: 1px solid #e2e4e8;
    box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.1);
    padding-bottom: 40px;
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1d1d1d;
    margin-bottom: 0;
    @include respond(mini-mobile) {
      font-size: 16px;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
  }

  &__item {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #e2e4e8;
    box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    transition: all 0.3s ease;
    margin-bottom: 10px;
    cursor: pointer;
    &.multiple-delivery {
      @media screen and (min-width: 62.5em) {
        min-width: 500px;
        width: 100%;
      }
      @include respond(62.5em) {
        width: 100%;
      }
    }
    &.active {
      box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12), 0px 0px 0px 3px #d4e5ff;
      border: 2px solid #22428f;
      .new-shop-n-ship__prompt__body__options__list__item {
        &__name h3 {
          color: #22428f;
        }
      }
    }
    &.disabled {
      border: 2px solid grey !important;
    }
    &__wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 24px;
      &:not(.discount) {
        padding-top: 0;
      }
      @include respond(mini-mobile) {
        padding: 16px;
      }
    }
    &__name-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include respond(mini-mobile) {
        display: block;
      }
      .recommended {
        border-radius: 50px;
        background-color: #e5edff;
        color: #22428f;
        font-size: 13px;
        padding: 8px 10px;
        margin-left: 10px;
        font-weight: 600;
        width: fit-content;

        @include respond(mini-mobile) {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
    &__name h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      color: #0f1414;

      @include respond(mini-mobile) {
        font-size: 13px;
      }
    }
    &__address span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #545859;
      @include respond(mini-mobile) {
        font-size: 14px;
      }
    }

    &__budget-delivery {
      background: #ffffff;
      border: 1px solid #e2e4e8;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      transition: all 0.3s ease;
      margin-bottom: 10px;
      padding: 10px 20px;
      cursor: pointer;
      &.active {
        box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.12),
          0px 0px 0px 3px #d4e5ff;
        border: 2px solid #22428f;
        .new-shop-n-ship__prompt__body__options__list__item {
          &__name h3 {
            color: #22428f;
          }
        }
      }

      .budget-option {
        margin-top: 20px;
        display: flex;
        gap: 8px;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 6px;

        @include respond(600px) {
          align-items: flex-start;
        }

        svg path {
          fill: #545859;
        }

        &.active {
          svg path {
            fill: #22428f !important;
          }
        }

        &.delivery-method {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @include respond(600px) {
            flex-wrap: wrap;
            margin-bottom: 10px;
          }
        }

        .budget-price {
          display: flex;
          align-items: center;
          padding-left: 10px;
          gap: 10px;

          @include respond(600px) {
            flex-wrap: wrap;
            margin-bottom: 10px;
          }
        }

        p {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 600;
          color: #545859;
          margin-bottom: 0;
          @include respond(600px) {
            font-size: 12px;
            margin-bottom: 1px;
          }
          &.active {
            color: #22428f;
            font-weight: 700;
          }
        }

        .price-tag {
          background-color: rgba(16, 197, 78, 0.1);
          color: #10c54e;
          padding: 3px 10px;
          border-radius: 50px;
          font-weight: 600;
        }
      }
    }
    &__radio {
      align-self: flex-end;
      transform: translate(-20px, 8px);
      &.quote.no-discount {
        transform: translate(-20px, -2px);
      }
      @include respond(830px) {
        &.no-discount {
          transform: translate(-20px, -2px);
        }
      }

      svg path {
        fill: #22428f;
      }
    }

    &__radio-alert {
      svg path {
        fill: #f05d3d !important;
      }
    }
    &__price {
      display: flex;
      gap: 1em;
      height: fit-content;

      &:not(.discount) {
        margin-top: 10px;
        // align-self: flex-end;
      }

      @include respond(1200px) {
        flex-direction: column;
      }
      span {
        padding: 3px 10px;
        font-style: normal;
        font-size: 17px;
        line-height: 19px;
        border-radius: 16px;
        font-weight: 700;
        @include respond(mini-mobile) {
          font-size: 13px;
        }
        &.no-discount {
          background: #d3d3d366;
          color: rgba(128, 128, 128, 0.844);
          text-decoration: line-through;
        }
        &:not(.no-discount) {
          background: rgba(16, 197, 78, 0.1);
          color: #10c54e;
        }
      }
    }
    &__accordion {
      .breakdown {
        margin-left: 1em;
        @include respond(320px) {
          margin-left: 0;
        }
      }

      &__details {
        display: flex;
        flex-direction: column;
        gap: 3em;
        margin-left: 1em;
        @include respond(320px) {
          margin-left: 0;
        }
      }
      &__element {
        width: 100%;
        &__wrap {
          display: flex;
          justify-content: space-between;
        }
        &__label {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #545859;
        }
        &__value {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #0f1414;
        }
      }
    }
  }

  &__disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }
  &__checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    margin-left: 1% !important;
    span:first-child svg {
      transform: scale(2);
      path {
        border: 2px solid #b7b7b7;
        border-radius: 4px;
      }
    }
    span:nth-child(2) {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #545859;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 74px;
    &__action {
      width: 100%;
      background: #ffffff;
      border: 1px solid #e2e4e8;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 14px 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #22428f;
      text-align: center;
      cursor: pointer;
    }
  }

  &__steps-container {
    p {
      font-size: 20px;
      font-weight: 600;
      color: #0f1414;
      @include respond(mini-mobile) {
        font-size: 18px;
      }
    }
  }
  &__steps {
    width: 100%;
    display: flex;
    gap: 4%;
    @include respond(mini-mobile) {
      margin-bottom: 0 !important;
    }
  }

  &__step {
    height: 5px;
    background: #dcebff;
    border-radius: 3px;
    cursor: pointer;
    &.complete {
      background: #22428f;
    }

    @include respond(smaller) {
      width: 100%;
    }
  }
}
