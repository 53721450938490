$desktop_average: 1350px;

.new-addresses {
  animation: fadeIn 0.5s;
  .empty-list {
    margin: 0;
    width: unset;
    div {
      width: 100%;
    }
  }
  .pagination {
    margin-right: 22px;
  }
  &__wrap {
    padding: 42px 56px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    @include respond(mobile) {
      padding: 24px;
    }
    @include respond(400px) {
      padding: 24px 16px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond(mobile) {
      width: 100%;
      align-items: flex-start;
      flex-direction: column;
      gap: 24px;
      button {
        width: 100%;
      }
    }
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: #0f1414;
    }
    button {
      background: #22428f;
      border-radius: 8px;
      padding: 12px 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      border: 0;
      outline: 0;
      color: #ffffff;
    }
  }
  &__list {
    &__wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
    }
    &__item {
      width: 48%;
      background: #ffffff;
      border: 1px solid #eef1f8;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      @include respond($desktop_average) {
        width: 100%;
      }
      &__wrap {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 24px;
        position: relative;
      }
      &__dropdown {
        width: 200px;
        display: none;
        position: absolute;
        left: 65%;
        z-index: 2;
        top: 50px;
        background: #ffffff;
        border: 1px solid #eef1f8;
        box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        @include respond(mobile) {
          left: 50%;
        }
        @include respond(400px) {
          left: 40%;
        }
        @include respond(350px) {
          left: 30%;
        }
        &__wrap {
          display: flex;
          flex-direction: column;
          padding: 8px 16px;
        }
        &__action {
          display: flex;
          align-items: center;
          justify-content: unset !important;
          gap: 12px;
          padding: 8px 0 !important;
          text-transform: unset !important;
          font-family: unset !important;
          cursor: pointer;
          button {
            width: 18px;
            height: 18px;
          }
          span {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
          }
          &:not(:last-child) {
            button,
            span {
              color: #22428f !important;
            }
          }
          &:last-child {
            button,
            span {
              color: #f51919 !important;
            }
          }
        }
        &.active {
          display: block;
          animation: fadeIn 0.5s;
        }
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include respond(mobile) {
          align-items: flex-start;
        }

        #address-list-item-dropdown {
          padding: 14px 12px;
        }

        #address-list-item-dropdown:hover {
          padding: 14px 12px;
          -webkit-text-decoration: none;
          text-decoration: none;
          background-color: #094d8f38;
        }
      }
      &__name {
        display: flex;
        align-items: center;
        gap: 16px;
        @include respond(mobile) {
          flex-direction: column;
          align-items: flex-start;
          span {
            order: 1;
          }
          h2 {
            order: 2;
          }
        }
        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          color: #0f1414;
        }
        span {
          background: rgba(17, 17, 17, 0.1);
          border-radius: 16px;
          padding: 8px 10px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: #0f1414;
        }
      }
      &__action {
        display: flex;
        align-items: center;
        gap: 3px;
        min-width: unset !important;
      }
      &__divider {
        display: block;
        width: 4px;
        height: 4px;
        background: #0f1414;
        border-radius: 50%;
      }
      &__body {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
      &__meta {
        display: flex;
        align-items: center;
        gap: 8px;
        @include respond(mobile) {
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          .new-addresses__list__item__divider {
            display: none;
          }
        }
        * {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #22428f;
        }
        &__postal {
          color: #545859;
        }
      }
      &__address {
        display: flex;
        align-items: center;
        gap: 16px;
        @include respond(mobile) {
          flex-direction: column;
          align-items: flex-start;
          .new-addresses__list__item__divider {
            display: none;
          }
        }
        * {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #545859;
          text-transform: capitalize;
        }
      }
      &__location {
        display: flex;
        align-items: center;
        gap: 8px;
        img {
          width: 20px;
          height: 14px;
        }
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #545859;
          text-transform: capitalize;
        }
      }
    }
  }
  &__modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: scroll;
    outline: 0;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    animation: fadeOut 0.5s;
    &.show {
      animation: fadeIn 0.5s;
      display: block;
    }
    &__wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    &__header {
      &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 29px 17.4%;
        @include respond(mini-mobile) {
          padding: 20px 24px;
        }
      }
      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #0f1414;
      }
      button {
        transform: scale(2);
        color: #22428f !important;
      }
      border-bottom: 1px solid #eef1f8;
      @include respond(mini-mobile) {
        border-bottom: unset;
      }
    }
    &__body {
      height: 100%;
      &__wrap {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: unset;
        padding: 0 22.1% 0 17.4%;
        gap: 81px;
        @include respond(1200px) {
          padding: 0 11.05% 0 17.4%;
        }
        @include respond(mini-mobile) {
          padding: 0 24px;
        }
      }
      &__label {
        padding: 60px 7.7% 0 0;
        height: 100%;
        border-right: 1px solid #eef1f8;
        @include respond(smaller) {
          display: none;
        }
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;
          color: #22428f;
          background: #f8fbff;
          border-radius: 8px;
          padding: 10px;
          min-width: 15rem;
        }
      }
      &__content {
        height: 100%;
        padding-top: 60px;
        flex: 2;
        @include respond(mini-mobile) {
          padding-top: 24px;
        }
      }
      &__form {
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
        &__item {
          display: flex;
          flex-direction: column;
          &__label {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #1d1d1d;
            margin-bottom: 20px;
          }
          &__input {
            transition: all 0.4s ease !important;
            text-transform: capitalize;
            label {
              font-style: normal;
              font-weight: 500 !important;
              font-size: 14px !important;
              line-height: 20px !important;
              color: #0f1414;
              margin-bottom: 4px !important;
              text-transform: none !important;
            }
          }
          &__button {
            margin: 64px 0 0 0 !important;
          }
        }
      }
    }
  }
}
.qoute__address__wrap {
  flex-direction: row !important;
}

@media screen and (max-width: 62.5rem) {
  .qoute__body__wrap {
    padding: 0 6.05% 0 6.4%;
  }
}
