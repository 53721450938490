.authentication {
  display: grid;
  grid-template-columns: 0.9fr 1fr;
  animation: fadeIn 1s;
  overflow: scroll;
  height: 100vh;

  &--signin {
    grid-template-columns: 1fr 0.9fr;
  }

  &__signin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../../assets/image/png/bg_profile.png'),
      linear-gradient(
        141.62deg,
        rgba(0, 117, 255, 0.93) 24.77%,
        rgba(255, 1, 1, 0.78) 89.33%
      );
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    &__intro-text {
      text-align: center;
      margin-bottom: 8rem;
      // padding: 8em 0 4em 0;

      @include respond(mobile) {
        margin-bottom: 0;
        padding: 8em 0 8em 0;
        & p {
          font-size: 1.35em;
          padding: 0.5em;
        }
      }
    }

    &__form {
      padding: 4em 0;
      border-radius: 15px;
      width: 35vw;
      min-width: 450px;
      background-color: #fff;
      position: fixed;
      z-index: 100;

      @include respond(smaller) {
        width: 100%;
        border: none;
        height: 100vh;
        border-radius: unset;
      }

      @include respond(mobile) {
        width: 100%;
        border: none;
      }
    }

    .inputGroup input[type='password'],
    .inputGroup input[type='text'] {
      width: 100%;
      border-bottom: 1 px solid #eeeff1;
    }

    .signin-form-controls {
      width: 88%;
      margin: 0 auto;
    }

    /* Using SCSS variables to store breakpoints */
    $breakpoint-mobile: 280px;
    @media (max-width: $breakpoint-mobile) {
      .signin-form-controls {
        width: 65%;
        margin: 0 auto;
      }
    }
  }

  @include respond(mobile) {
    display: flex;
    flex-direction: column-reverse;
    // height: 100vh;
    justify-content: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    padding-top: 12rem;

    &__header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 3rem 6rem;

      @include respond(mobile) {
        padding: 3rem 2rem;
      }

      &--auth-header {
        border-bottom: none;
        position: unset;
      }

      & button {
        background-color: var(--color-yellow);
        font-size: 1.3rem;
        padding: 1.3rem;
        border-radius: 0.8rem;
        border: none;
        font-weight: bold;
        min-width: 8rem;
      }
    }

    &__fields {
      display: flex;
      flex-direction: column;
      padding: 0rem 3rem;
      flex-grow: 1 !important;
      position: relative;
      justify-content: center;

      @include respond(medium) {
        width: 100% !important;
        padding: 0 2rem;
        justify-content: flex-start;
      }

      @include respond(mobile) {
        width: 100% !important;
        padding: 0 2rem;
        padding-bottom: 15rem;
        justify-content: flex-start;
      }
    }

    @include respond(medium) {
      width: 80%;
    }

    @include respond(tablet) {
      width: 90%;
    }

    @include respond(mobile) {
      width: 100%;
      padding-top: 35em;
      overflow-y: scroll;
    }
  }

  &__image {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
      url('../../assets/image/png/bg_profile.png');

    display: flex;
    // height: 100vh;
    padding: 0 2rem;

    @include respond(mobile) {
      display: none;
    }
  }

  &__profile {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    line-height: 1.5;

    & img {
      font-size: 4rem;
      font-weight: 700;
      margin-bottom: 2em;
    }

    & h2 {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 1em;
    }

    & div {
      margin-bottom: 4em;
    }

    &__item {
      font-size: 1.5em;
      font-weight: 100;
      color: #fff;
    }

    &__terms {
      font-size: 1.2em;
      color: #fff;
    }

    @include respond(medium) {
      justify-content: flex-start;

      & img {
        margin-top: 3rem;
        margin-bottom: 2rem;
      }
    }
  }

  figure > img {
    width: 150px;
    vertical-align: middle;
  }

  &__terms {
    color: var(--color-gray2);
    width: 85%;
    margin: 0 auto;
    line-height: 1.5;
    font-weight: 500;
    margin-top: 2.5rem;
    font-size: 1.2rem;
    margin-bottom: 2.5rem !important;
    align-self: flex-start;
    text-align: center;

    @include respond(mobile) {
      width: 100% !important;
    }
  }

  &__forgot-password {
    font-size: 1.45rem;
    margin-bottom: 3rem;
    color: var(--color-yellow);
    display: block;
    align-self: flex-end;
  }

  &__signup-link {
    font-size: 1.4rem;

    @include respond(smaller) {
      font-size: 1.25rem;
    }

    & span {
      color: #586274;
      font-weight: 500;
      text-align: center;
    }

    & a {
      font-weight: 600;
      color: var(--color-blue-dark);
      text-decoration: underline;
    }
  }

  &__button {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
  }

  &__have-account {
    font-size: 1.5em;
    padding: 1em;
    text-align: center;
    font-weight: 500;

    & span {
      color: var(--color-yellow);
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: var(--color-blue-dark4);
      }
    }
  }

  .inputGroup input[type='password'],
  .inputGroup input[type='text'] {
    width: 100%;
    border-bottom: 1 px solid #eeeff1;
  }
}
