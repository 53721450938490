.recent-shipments-container {
  padding: 4%;
  @include respond(769px) {
    padding: 100px 16px;
  }

  .past-shipment-type .MuiAutocomplete-endAdornment {
    top: calc(50% - 14px) !important;
  }
  .recent-shipment-filters {
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
  }

  .recent-shipment-filters span {
    font-size: 12px;
  }

  .css-1to3hj-MuiFormControl-root-MuiTextField-root {
    height: 100%;
    border-radius: 8px;
  }

  .css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root {
    padding-left: 2px;
    height: 100%;
    border-radius: 8px;
  }

  .css-11lq3yg-MuiGrid-root {
    display: flex;
    justify-content: space-between;
  }

  .MuiFormControl-marginNormal {
    margin: 0px;
    background: #ffffff !important;
    border: 1px solid #e2e4e8 !important;
    border-radius: 8px !important;
  }

  .MuiInput-root {
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 56px;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
  }

  .MuiInput-underline:before {
    border: none;
    display: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
    display: none;
  }

  #startDate,
  #endDate {
    padding: 2% 5%;
  }

  .recent-shipments-controls {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
  }

  .recent-shipments-control {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 3% 0;
  }

  .recent-shipments-control-items {
    margin: 2% 0;
  }

  .recent-shipments-date-filter-controls {
    .MuiInputAdornment-positionEnd {
      margin: 0px;
    }

    .MuiIconButton-root {
      padding: 0px;
      padding-right: 8px;
    }

    .MuiButtonBase-root {
      transform: scale(1.5);
    }
  }

  .recent-shipments-filters-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    @include respond(768px) {
      flex-direction: column;
    }
  }

  .recent-shipments-ship-now {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    @include respond(768px) {
      margin-top: 10px;
      width: 50%;
    }
    button {
      width: 100%;
      background: #22428f;
      box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.05);
      border-radius: 4px;
      padding: 11.5px 16.5px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: #ffffff;
      outline: 0;
      border: 0;
    }
  }

  .shipment-item {
    cursor: pointer;
  }

  .past-shipment-no-result-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 450px;
  }

  .past-shipment-no-result-found-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    text-align: center;

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #0f1414;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #545859;
    }
  }

  .past-shipment-list-pagination {
    display: flex;
    justify-content: flex-end;

    .pagination {
      margin: 0px !important;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    @media screen and (min-width: 480px) {
      .pagination {
        width: fit-content;
        justify-content: flex-end;
      }
    }
  }

  @media screen and (min-width: 480px) {
    #search,
    #shipment-type {
      margin-right: 10px;
    }

    .recent-shipment-filters {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-left: 0;
      @include respond(768px) {
        justify-content: center;
      }
    }

    .recent-shipment-filters span {
      font-size: 16px;
    }

    .recent-shipments-controls {
      flex-direction: row;
      justify-content: space-between;
    }

    .recent-shipments-control-items-shipment-type {
      width: 280px;
    }
  }
}
