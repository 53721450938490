.recent-shipment-item-status,
.recent-shipment-item {
  &.status {
    padding: 4px 10px;
    border-radius: 16px;
    font-size: 12px;
    line-height: 16.2px;
    text-align: center;
  }

  &.draft {
    background-color: #e9e8e8;
    color: #4d4d4d;
    padding: 4px 20px;
  }

  &.pendingconfirmation {
    background-color: #efa5161a;
    color: #efa516;
    border: 1px solid #efa516;
  }

  &.statusunavailable {
    background-color: #df24031a;
    color: #efa516;
  }

  &.delivered,
  &.documentuploaded,
  &.confirmed {
    background-color: #03df411a;
    color: #03df41;
    border: 1px solid #03df41;
  }

  &.deliveryinprogress,
  &.receivedathub,
  &.arrivednigeria,
  &.awaitingpickup,
  &.intransit,
  &.paymentpending,
  &.awaitingdocumentupload,
  &.shipmentprocessing,
  &.riderassigned,
  &.cancellationpending {
    background-color: #df24031a;
    color: #efa516;
    border: 1px solid #efa516;
  }

  &.cancelled,
  &.pickupfailed,
  &.deliveryfailed,
  &.clarificationneeded {
    background-color: #df24031a;
    color: #ef5b16;
    border: 1px solid #ef5b16;
  }
}
