.new-referral {
  animation: fadeIn 0.5s !important;
  &__wrap {
    padding: 40px 0 156px 56px;
    display: flex;
    flex-direction: column;
    gap: 56px;
    .empty-list {
      margin-left: 0;
    }
    @include respond(769px) {
      .empty-list {
        margin-left: auto;
      }
      padding: 100px 24px 60px 24px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__groups {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
    &__group {
      display: flex;
      flex-direction: column;

      gap: 16px;
    }
    &__header * {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      margin: 0;
      color: #0f1414;
    }
    &__text * {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      color: #545859;
    }
    &__cta {
      display: flex;
      align-items: center;
      gap: 16px;
      @include respond(700px) {
        align-items: unset;
        flex-direction: column;
      }
      &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        background: #f4f9fb;
        border-radius: 8px;
        padding: 12px 16px;
        transition: all 1s ease;
        @include respond(mini-mobile) {
          gap: 6px;
          flex-direction: column-reverse;
          padding: 12px 10px;
        }
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #545859;

          @include respond(mini-mobile) {
            font-size: 12px;
          }
        }
        button {
          font-family: unset;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #22428f;
          text-transform: none;
        }
      }
      &__socials {
        display: flex;
        align-items: center;
        gap: 16px;
      }
      &__social {
        display: flex;
        justify-content: center;
        width: 48px;
        height: 48px;
        background: #f4f9fb;
        border-radius: 38px;
        padding: 12px;
        cursor: pointer;
      }
    }
  }
  &__list {
    &__wrap {
      display: flex;
      flex-direction: column;

      gap: 24px;
    }
    &__header * {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      margin: 0;
      color: #0f1414;
    }
    &__items {
      border: 1px solid #eef1f8;
      border-radius: 8px;
      width: 600px;
      @include respond(tablet) {
        width: 100%;
      }
      &__wrap {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px 0 24px 24px;
      }
    }
    &__item {
      display: flex;
      gap: 16px;
      &__meta {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      &__name {
        display: flex;
        align-items: center;
        gap: 8px;
        h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;
          margin: 0;
          color: #0f1414;
        }
      }
      &__date * {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
        color: #545859;
      }
    }
  }

  .lsp-business-alert {
    background-color: #ffdc23;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    margin-bottom: 50px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    color: #0f1414;
    font-weight: 500;
    text-decoration: unset !important;

    &:hover {
      text-decoration: none !important;
    }
    @include respond(700px) {
      margin-top: 20px;
    }
  }
}
