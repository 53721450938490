.delete-overlay {
  @include overlayBackground;
  &--show {
    opacity: 1;
    visibility: visible;
  }

  &__content {
    background-color: #fff;
    box-shadow: 0 1rem 4rem rgba(#000, 0.2);
    border-radius: 0.8rem;
    overflow: hidden;
    transform: scale(0.5);
    transition: all 0.4s 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 3rem;

    @include respond(mini-mobile) {
      padding: 1rem;
      width: 90vw;
    }

    & figure {
      margin-top: 1rem;
      & svg {
        fill: #645649;
        transform: scale(1.7);
      }
    }

    & h2 {
      margin: 1.2rem;
      font-size: 2.2rem;
      font-weight: 600;
      color: rgb(97, 92, 92);
    }

    & p {
      font-size: 1.3rem;
      text-align: center;
    }

    & button {
      font-size: 1.6rem;
      padding: 0.5rem 0.8rem;
      border: none;
      background: none;

      &:first-child {
        margin-right: 2rem;
        color: var(--color-blue-dark);
      }
      &:last-child {
        border-radius: 0.5rem;
        padding: 1rem;
        color: red;
        font-weight: 600;
      }
    }

    &--show {
      opacity: 1;
      transform: scale(1);
      font-weight: 0;
      animation: zoomInOut 0.4s 0.2s;
    }

    .web-address-guide {
      display: block;
    }
    .resource-guide-img {
      width: 600px;
    }
    .tab-address-guide {
      display: none;
    }
    .mobile-address-guide {
      width: 100%;
      display: none;
    }
    @include respond(800px) {
      .resource-guide-img {
        width: 400px;
      }
      .web-address-guide {
        display: none;
      }
      .tab-address-guide {
        display: block;
      }
    }
    @include respond(500px) {
      .tab-address-guide {
        display: none;
      }
      .mobile-address-guide {
        display: block;
      }
      .resource-guide-img {
        width: 100%;
      }
    }
  }
  #resource-layout {
    padding: 0;
    min-height: 500px;
    max-height: 700px;
    overflow: auto;
    position: relative;

    @include respond(600px) {
      min-height: 300px;
    }
  }
  .cta-group {
    display: flex;
    height: fit-content;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    position: absolute;
    left: 0;
    top: 6px;

    img {
      width: 26px;
    }

    @include respond(780px) {
      top: 4px;
      padding: 0 10px;

      img {
        width: 18px;
      }
    }
  }

  .resource-prompt-container {
    background-color: white;
    width: 700px;
    position: relative;
    height: fit-content;
    overflow: auto;

    @include respond(1000px) {
      width: 800px;
    }
    @include respond(900px) {
      width: 100%;
    }

    h3 {
      font-size: 20px;
      font-weight: 600;

      @include respond(580px) {
        font-size: 18px;
      }
    }
  }

  .prohibited-list-container::-webkit-scrollbar {
    width: 0;
  }

  .prohibited-list-container {
    background-color: #ffffff;
    width: 600px;
    height: 700px;
    overflow: auto;
    margin: 0 auto;

    @include respond(720px) {
      width: 500px;
    }

    @include respond(580px) {
      width: 96%;
      height: 96vh;
      padding-bottom: 20px;
    }

    .heading-wrap {
      display: flex;
      justify-content: space-between;
      padding: 20px;

      @include respond(580px) {
        flex-direction: column-reverse;
        .close-btn {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .list-wrap {
      padding: 18px 15px;
      border-bottom: 1px solid #ccc;
      @include respond(580px) {
        padding-bottom: 8px 5px;
      }
    }
    .list-wrap:nth-last-child(1) {
      padding-bottom: 10px;
      border-bottom: none;
    }

    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 5px;
      cursor: pointer;
    }
    img {
      transition: transform 0.3s ease;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color: #000000;

      @include respond(580px) {
        font-size: 15px;
        line-height: 28px;
      }
    }

    .description {
      padding-top: 10px;
      transition: transform 0.3s ease;
      font-size: 15px;
      line-height: 28px;
    }
  }
}
