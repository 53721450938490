.form {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.get-quotes-form {
  width: 100%;

  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 58px;
    color: #22428f;
    text-align: left;
    margin-bottom: 2.5rem;
    padding-top: 2rem;
  }

  .quote-form-item {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
  }

  .column {
    width: 100%;
    height: 48px;
    margin-bottom: 2rem;
    background: #ffffff;
    border-radius: 8px;
    text-align: left;

    &:nth-child(1) {
      margin-top: 15px;
    }

    &:nth-child(2) {
      margin-top: 25px;
    }
  }

  .get-quote-submit-button {
    width: 100%;
    color: #ffffff;
    background: #22428f;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-transform: inherit;
    padding: 20px 0;
    margin-bottom: 10px;
  }

  .get-quote-submit-button:hover {
    background: #22428f;
  }

  .quotes-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #0f1414;
    text-align: left;
    margin-bottom: 1rem;
  }

  .quote-weight {
    margin-bottom: 2rem;
  }

  .quote-weight-details p {
    margin-bottom: 0;
  }

  .validation-error {
    color: red;
    padding: 5px 0;
    text-align: left;
  }
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .form {
    width: 100%;
    min-width: 500px;
    // height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  .get-quotes-form {
    width: 100%;

    h2 {
      font-size: 32px;
      font-weight: 600;
      line-height: 58px;
      color: #22428f;
      text-align: left;
      margin-bottom: 2.5rem;
    }

    .quote-form-item {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 3rem;
    }

    .sender-details,
    .receiver-details {
      display: flex;
      justify-content: space-between;
    }

    .column {
      width: 48%;
      min-width: 195px;
      height: 48px;
      background: #ffffff;
      border-radius: 8px;
      text-align: left;
      margin-top: 0px;

      &:nth-child(1) {
        margin-top: 0px;
      }

      &:nth-child(2) {
        margin-top: 0px;
      }
    }

    .get-quote-submit-button {
      width: 100%;
      color: #ffffff;
      background: #22428f;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-transform: inherit;
      padding: 20px 0;
      margin-bottom: 10px;
    }

    .get-quote-submit-button:hover {
      background: #22428f;
    }

    .quotes-label {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #0f1414;
      text-align: left;
      margin-bottom: 1rem;
    }

    .quote-weight {
      margin-bottom: 2rem;
    }

    .quote-weight-details p {
      margin-bottom: 0;
    }

    .validation-error {
      color: red;
      padding: 5px 0;
      text-align: left;
    }
  }
}
